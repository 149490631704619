.nav-language {
  // margin-right: 37px;
  border-radius: 5px;
  position: relative;
}
.nav-language img {
  width: 25px;
}
// HEADER
.nav-language .header > div {
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s all;
}
.nav-language.open .header > div {
  background: #CDCDCD;
}

.nav-language img,
.nav-language .language {
  margin-right: 16px;
}
.nav-language .language {
  color: #262F39;
  font-weight: 500;
}

.nav-language .header svg {
  transition: 0.3s linear;
}
.nav-language.open .header svg path {
  fill: #484848;
}
.nav-language.open .header svg {
  transform: rotate(-180deg);
}

// DROPDOWN
.nav-language .dropdown {
  position: absolute;
  bottom: -2px;
  left: 0;
  padding: 8px 4px 8px;
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 100%;
  transform: translateY(100%);
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: 0.3s linear;
  z-index: 9;
}
.nav-language.open .dropdown {
  height: 78px;
  opacity: 1;
}
.nav-language .dropdown .language {
  cursor: pointer;
}
.nav-language .dropdown .language:not(:last-child) {
  margin-bottom: 10px;
}

// IN HEADER NAV
.header-nav .nav-language {
  margin-right: 0;
}
.header-nav .nav-language .header svg path {
  fill: #484848;
}
.header-nav .nav-language .language {
  margin-right: 11px;
}

@media (max-width: 991.98px) {
  .nav-language {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  // IN HEADER NAV
  .header-nav .nav-language {
    display: block;
  }
  .nav-language img {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .nav-language {
    margin-right: 20px;
  }
  .nav-language .flag,
  .nav-language .language {
    margin-right: 10px;
  }
}