.ant-modal.register-modal {
  margin-top: 50px;
  margin-bottom: 50px;
}

// HEADER
.ant-modal.register-modal .header {
  display: block;
  text-align: center;
  margin-bottom: 24px;
}
.ant-modal.register-modal .header img {
  width: 170px;
  margin-bottom: 24px;
}
.ant-modal.register-modal .header h4 {
  color: #8fc045;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}
.ant-modal.register-modal .header p {
  color: #262f39;
  font-weight: 500;
  line-height: 28px;
}
.ant-modal.register-modal .header p span {
  color: #8fc045;
  font-weight: 700;
}

.ant-input-affix-wrapper input.ant-input {
  border: unset !important;
}
.ant-modal.register-modal .ant-input-affix-wrapper input.ant-input {
  background: #fff;
  padding: 0 0 0 8px;
}

.ant-modal.register-modal p.footer {
  margin-top: 25px;
  text-align: center;
  font-size: 16px;
  color: #262f39;
}
.ant-modal.register-modal p.footer .ant-btn {
  color: #8fc045;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 700;
  border: unset;
  padding: 0;
  height: unset;
  box-shadow: unset;
  border-bottom: 1px solid #8fc045;
  border-radius: 0;
  margin-left: 5px;
  line-height: 20px;
}
.ant-modal.register-modal small {
  font-size: 14px;
  font-weight: 500;
  margin-right: 4px;
}
.ant-modal .ant-btn.forgot-btn {
  color: #8fc045;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  border: unset;
  box-shadow: unset;
}

// OR
.ant-modal .or {
  position: relative;
}
.ant-modal .or hr {
  margin: 30px 0;
}
.ant-modal .or span {
  background-color: #fff;
  color: #626d7d;
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 16px;
}

.ant-modal.register-modal .ant-modal-body .close {
  position: absolute;
  top: 29px;
  right: 35px;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .ant-modal.register-modal {
    width: 90% !important;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .ant-modal.register-modal .ant-modal-body {
    padding: 24px 16px;
  }

  // HEADER
  .ant-modal.register-modal .header {
    margin-bottom: 16px;
  }
  .ant-modal.register-modal .header img {
    width: 80px;
    margin-bottom: 16px;
  }
  .ant-modal.register-modal .header h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .ant-modal.register-modal .header p {
    font-size: 11px;
    line-height: 20px;
  }

  .ant-modal.register-modal p.footer {
    margin-top: 16px;
    font-size: 12px;
  }
  .ant-modal.register-modal p.footer .ant-btn {
    font-size: 12px;
    margin-left: 5px;
    line-height: 15px;
  }

  // OR
  .ant-modal .or hr {
    margin: 30px 0;
  }
  .ant-modal .or span {
    font-size: 10px;
    padding: 5px 16px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ant-modal.register-modal {
    width: 400px !important;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .ant-modal.register-modal {
    width: 450px !important;
  }

  // HEADER
  .ant-modal.register-modal .header {
    margin-bottom: 16px;
  }
  .ant-modal.register-modal .header img {
    width: 100px;
    margin-bottom: 16px;
  }
  .ant-modal.register-modal .header h4 {
    font-size: 22px;
    margin-bottom: 16px;
  }
  .ant-modal.register-modal .header p {
    font-size: 13px;
    line-height: 24px;
  }

  .ant-modal.register-modal p.footer {
    margin-top: 16px;
    font-size: 14px;
  }
  .ant-modal.register-modal p.footer .ant-btn {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  // OR
  .ant-modal .or hr {
    margin: 30px 0;
  }
  .ant-modal .or span {
    font-size: 12px;
    padding: 5px 16px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .ant-modal.register-modal .header img {
    width: 120px;
  }
}
