.ant-modal.pop-up {
  width: 500px !important;
}
.ant-modal.pop-up .ant-modal-content {
  text-align: center;
}
.ant-modal.pop-up .ant-modal-body {
  padding: 32px;
  position: relative;
}

// DELETE ICON
.ant-modal.pop-up .icon {
  background: #fdeeee;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 24px;
}
.ant-modal.pop-up .icon svg {
  width: 30px;
  height: 30px;
}

// APPROVE ICON
.ant-modal.pop-up .approve-icon {
  height: 110px;
  width: 110px;
  margin-bottom: 16px;
}

.ant-modal.pop-up h3 {
  color: #262f39;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 16px;
}
.ant-modal.pop-up p {
  color: #484848;
  line-height: 24px;
  margin-bottom: 40px;
}
