.table-search .ant-input-affix-wrapper {
  padding: 0.875rem 1rem 0.875rem;
  background: rgba(143, 192, 69, 0.1);
  border: 1px solid #8fc045;
  border-radius: 5px;
}

.table-search .ant-input-affix-wrapper input.ant-input {
  background-color: transparent;
  padding-left: 0;
}