.table-style-2 {
  border: 1px solid #ddd;
  border-radius: 0.75rem;
  overflow: hidden;
}

.table-style-2 .ant-table-content thead tr th {
  background-color: #fff;
  color: #262f39;
  font-size: 0.875rem;
  font-weight: 600;
}
.table-style-2 .ant-table-thead > tr > th {
  border-bottom: 1px solid #ddd;
  padding: 1.25rem 1rem;
}

.table-style-2 .ant-table-content td {
  font-size: 0.875rem;
}
.table-style-2 .ant-table-content tr:not(:last-child) td {
  border-bottom: 1px solid #ddd;
}

// STATUS
.table-style-2 .ant-table-content td .status-2 {
  font-weight: 600;
  padding-left: 0.75rem;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
}
.table-style-2 .ant-table-content td .status-2::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}
.table-style-2 .ant-table-content td .status-2.warning::before {
  background: #ffa800;
}
.table-style-2 .ant-table-content td .status-2.danger::before {
  background: #d93f21;
}
.table-style-2 .ant-table-content td .status-2.purple::before {
  background: #9b51e0;
}
.table-style-2 .ant-table-content td .status-2.hidden::before {
  background: #1f8181;
}
.table-style-2 .ant-table-content td .status-2.blue::before {
  background: #006fad;
}
.table-style-2 .ant-table-content td .status-2.pink::before {
  background: #cd6688;
}
.table-style-2 .ant-table-content td .status-2.success::before {
  background: #8fc045;
}
.table-style-2 .ant-table-content td .status-2.in-process::before {
  background: #262f39;
}

// BUTTONS
.ant-btn.table-btn {
  font-size: 0.75rem;
  font-weight: 500;
  min-width: 6.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  height: unset;
  border: unset;
  box-shadow: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-btn.table-btn svg {
  height: 0.913rem;
}

.ant-btn.table-btn.success {
  background: rgba(143, 192, 69, 0.1);
  color: #8fc045;
}
.ant-btn.table-btn.primary {
  background: rgba(0, 111, 173, 0.1);
  color: #006fad;
}
.ant-btn.table-btn.black {
  background: rgba(38, 47, 57, 0.1);
  color: #262f39;
}
.ant-btn.table-btn.view {
  background: rgba(31, 129, 129, 0.1);
  color: #1f8181;
}
.ant-btn.table-btn.danger {
  background: rgba(217, 63, 33, 0.1);
  color: #d93f21;
}
.ant-btn.table-btn.yellow {
  background: rgba(138, 139, 72, 0.1);
  color: #8a8b48;
}
.ant-btn.table-btn.warning {
  background: rgba(255, 168, 0, 0.1);
  color: #ffa800;
}
.ant-btn.table-btn.info {
  background: rgba(23, 162, 184, 0.1);
  color: #17a2b8;
}
