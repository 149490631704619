.ant-select {
  background: #fff;
  border: 1px solid #cfd3da;
  border-radius: 5px;
  padding: 9px 8px;
  display: block;
  width: 100%;
}
.ant-select-selection-placeholder {
  color: #626d7d;
  font-weight: 400;
  font-size: 14px;
}
.ant-select.ant-select-focused .ant-select-selector {
  box-shadow: unset !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: unset;
  color: #262f39;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 1199.98px) {
  .ant-select {
    padding: 7px 6px;
  }
  .ant-select-selection-placeholder {
    font-size: 12px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 12px;
  }
  .ant-select svg {
    width: 10px;
  }
}
