.rtl .dashboard-pg .item {
  padding: 30px 40px 30px 10px;
}
.rtl .dashboard-pg .item svg {
  margin-left: 40px;
  margin-right: 0;
}
.rtl .dashboard-pg .item p span {
  margin-left: 12px;
  margin-right: 0;
}