@import './select';

@import './table-filters/table-filters';

@import './profile-form/profile-form';

@import './form-type_4/form-type-4';

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

// FORM
.form .input {
  margin-bottom: 16px;
}
.form label {
  color: #484848;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
}
.form .ant-btn.submit {
  background: #8fc045;
  color: #fff;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  height: 55px;
  width: 100%;
  border: unset;
  margin-top: 10px;
  letter-spacing: 0.1em;
}
.form .ant-btn.submit:hover {
  background: #77aa2a;
}

@media (max-width: 767.98px) {
  // FORM
  .form .input {
    margin-bottom: 12px;
  }
  .form label {
    font-size: 12px;
    margin-bottom: 6px;
  }
  .form .ant-btn.submit {
    border-radius: 4px;
    font-size: 14px;
    height: 50px;
    margin-top: 10px;
  }
}
