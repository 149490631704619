// .rtl .nav-language {
//   margin-right: 0;
//   margin-left: 37px;
// }

.rtl .nav-language img,
.rtl .nav-language .language {
  margin-right: 0;
  margin-left: 16px;
}

// IN HEADER NAV
.rtl .header-nav .nav-language .language {
  margin-right: 0;
  margin-left: 11px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .rtl .nav-language {
    margin-right: 0;
    margin-left: 20px;
  }
  .rtl .nav-language .flag,
  .rtl .nav-language .language {
    margin-right: 0;
    margin-left: 10px;
  }
}