#main-form-style {
  .normal-input {
    label {
      font-size: 0.875rem;
      color: #6d6d6d;
      margin-bottom: 0.5rem;
    }

    img {
      width: 1.75rem;
      margin-bottom: 0.5rem;
    }

    textarea {
      background: rgba(221, 221, 221, 0.1);
    }

    .ant-input {
      font-size: 1rem;
      font-weight: 600;
      border: 1px solid #ddd;
      border-radius: 0.5rem;
      padding: 1rem 0.875rem;
    }

    .ant-input-affix-wrapper {
      font-size: 1rem;
      font-weight: 600;
      border: 1px solid #ddd;
      border-radius: 0.5rem;
      padding: 0.875rem 0.875rem;
    }

    .ant-input::placeholder {
      font-size: 1rem;
      font-weight: 400;
      color: #aaa;
    }

    .ant-input:focus,
    .ant-input:hover {
      border: 1px solid #8fc045;
    }

    .ant-input-affix-wrapper input.ant-input {
      border: unset !important;
      padding: 0.12rem;
    }

    .ant-input-group-wrapper.addonBefore .ant-input {
      border-radius: 0 0.5rem 0.5rem 0;
    }
    
    .ant-input-group-addon {
      border-radius: 0.5rem 0 0 0.5rem;
      width: 4rem;
    }

    .ant-input-group-wrapper.addonBefore.green .ant-input-group-addon {
      background: rgba(143, 192, 69, 0.05);
    }
    .ant-input-group-wrapper.addonBefore.blue .ant-input-group-addon {
      background: rgba(0, 111, 173, 0.05);
    }
  }
}
