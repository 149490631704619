.services-dashboard {
  margin: 20px 30px;
}
.services-pg.services-dashboard {
  padding: 0;
}

.services-pg.services-dashboard .services-details .item .details p {
  font-size: 13px;
}
.services-pg.services-dashboard
  .services-details
  .item
  .details
  div:first-child {
  margin-right: 10px;
}

@media (max-width: 1199.98px) {
  .services-dashboard {
    margin: 16px 16px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .services-pg.services-dashboard .main-services .item .logo {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  .services-pg.services-dashboard .main-services .item .logo svg {
    width: 45px;
  }
  .services-pg.services-dashboard .main-services .item h4 {
    font-size: 18px;
  }

  .services-pg.services-dashboard .services-details .item .details p {
    font-size: 11px;
  }
  .services-pg.services-dashboard .services-details .item .ant-btn.add {
    width: 160px;
    font-size: 12px;
    padding: 14px;
  }
  .services-pg.services-dashboard .services-details .item .ant-btn.more {
    font-size: 12px;
  }
  .services-pg.services-dashboard .services-details .item .ant-btn.add svg {
    margin-left: 8px;
  }
}
