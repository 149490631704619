#pagination {
  .ant-pagination-item,
  .ant-pagination-prev button,
  .ant-pagination-next button {
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 6px;
    color: #aaa;
    font-weight: 600;
  }

  .ant-pagination-item-active {
    background-color: #8fc045;
    border-color: #8fc045;
    a {
      color: #fff !important;
    }
  }

  .ant-pagination-item:hover {
    border-color: #8fc045;
    a {
      color: #8fc045;
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #8fc045;
  }

  .ant-pagination-options {
    display: none;
  }

  .ant-pagination-total-text {
    color: #aaa;
    font-weight: 600;
  }
}
