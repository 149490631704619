// TOOLBAR
.demo-toolbar-custom {
  border: 1px solid #d9d9d9;
  border-radius: 5px !important;
}
.rdw-editor-toolbar.demo-toolbar-custom .rdw-option-wrapper {
  border-color: transparent;
}
// EDITOR
.demo-editor {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 0px 16px;
  color: #222;
  font-size: 14px;
  // min-height: 500px;
}

.rdw-link-modal {
  height: unset !important;
}
