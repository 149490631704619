#main-form-style {
  .select-dropdown {
    label {
      font-size: 0.875rem;
      color: #6d6d6d;
      margin-bottom: 0.5rem;
    }

    .ant-select {
      border: 1px solid #ddd;
      border-radius: 0.5rem;
      padding: 0;

      .ant-select-selector {
        min-height: 3.55rem;

        .ant-select-selection-item {
          font-size: 1rem;
          line-height: 3.5rem;
          align-items: center;
        }

        .ant-select-selection-search-input {
          height: 100%;
          font-size: 1rem;
          font-weight: 600;
        }
      }

      .ant-select-selection-placeholder {
        font-size: 1rem;
        color: #AAA;
        line-height: 3.5rem;
      }
    }
  }
}