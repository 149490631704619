.ant-table {
  overflow: hidden;
}
.ant-table-content {
  overflow-x: auto;
}
.ant-table-content thead tr th {
  background-color: #fff;
  color: #9fa2b4;
  font-size: 14px;
  font-weight: 700;
}
.ant-table-thead > tr > th {
  border-bottom: 1.5px solid #dfe0eb;
  padding: 0 16px 13px 16px;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent;
}
.ant-table-content td {
  color: #262f39;
  font-weight: 500;
  font-size: 14px;
}
.ant-table-tbody > tr > td {
  border-bottom: unset;
}

// UNREAD
.ant-table-tbody tr.unread {
  background: #8fc04515;
}

// LIGHT
.ant-table .light {
  color: #737373;
}

// VIEW LOG
.ant-table .view-log {
  font-weight: 500;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

// STATUS
.status {
  display: inline-block;
  text-align: center;
  letter-spacing: 0.5px;
  min-width: 125px;
  padding: 0.55rem 8px;
  border-radius: 0.25rem;
  font-weight: 700;
  font-size: 0.6rem;
  color: #ffffff;
  text-transform: uppercase;
}
.status.lg {
  width: 140px;
}
.status.danger {
  background: #d00f24;
}
.status.primary {
  background: #006fad;
}
.status.success {
  background: #348a52;
}
.status.warning {
  background: #ffa800;
}
.status.dark-blue {
  background: #243d8c;
}
.status.dark {
  background: #262f39;
}
.status.wait-sign {
  background: #b46060;
}
.status.signed {
  background: #6527be;
}
.status.signed {
  background: #6527be;
}

// IMAGE
.ant-table-content td .image {
  background-position: center;
  background-size: cover;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

// AVATAR
.ant-table-content td .ant-avatar {
  font-size: 14px !important;
  margin-right: 8px;
  min-width: 30px;
}

// UPLOAD INFO
.ant-table-content td .upload-info {
  cursor: pointer;
  min-width: 85px;
  display: inline-block;
}

// REQUEST BTN
.ant-table-content td .ant-btn.request {
  background: #8fc045;
  color: #ffffff;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.5px;
  border-radius: 6px;
  padding: 8px 20px;
  height: unset;
  border: unset;
}
.ant-table-content td .ant-btn.request:hover {
  background: #77aa2a;
}

// EDIT BTN
.ant-table-content td .ant-btn.edit {
  background: #8fc045;
  color: #ffffff;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.5px;
  border-radius: 6px;
  padding: 8px 9px;
  height: unset;
  border: unset;
}
.ant-table-content td .ant-btn.edit:hover {
  background: #77aa2a;
}

// Reciept
.ant-table-content td .ant-btn.reciept {
  background-color: transparent;
  border: unset;
  box-shadow: unset;
  color: #484848;
  font-weight: 500;
  font-size: 14px;
}
.ant-table-content td .ant-btn.reciept svg {
  margin-left: 23px;
}
.ant-table-content td .ant-btn.reciept span {
  text-decoration: underline;
}

// LINK
.ant-table-content td .link {
  color: #8fc045;
  text-decoration: underline;
}

// WHATSAPP ICON
.ant-table-content .whatsapp-icon {
  cursor: pointer;
}

// MESSAGE SVG
.message-svg.active path {
  stroke: #8fc045;
}

// ==================== BUTTONS ====================
// SMALL BTNS
.ant-btn.btn-sm {
  width: 36px;
  height: 35px;
  border-radius: 6px;
  padding: unset;
  border: unset;
}
.ant-btn.btn-sm svg {
  height: 20px;
}
.ant-btn.btn-md {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  min-width: 80px;
  border-radius: 6px;
  padding: 8px 9px;
  height: unset;
}
.ant-btn.btn-lg {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  min-width: 107px;
  border-radius: 6px;
  padding: 8px 9px;
  height: unset;
}
.ant-btn.success {
  background: rgba(143, 192, 69, 0.1);
  color: #8fc045;
  border: 1px solid #8fc045;
}
.ant-btn.black {
  background: rgba(38, 47, 57, 0.1);
  color: #262f39;
  border: 1px solid #262f39;
}
.ant-btn.danger {
  background: rgba(255, 47, 72, 0.1);
  color: #ff2f48;
  border: 1px solid #ff2f48;
}
.ant-btn.blue {
  background: #006fad;
  color: #fff;
  border: 1px solid #006fad;
}
.ant-btn.primary {
  background: rgba(0, 111, 173, 0.1);
  color: #006fad;
  border: 1px solid #006fad;
}
.ant-btn.warning {
  background: rgba(255, 168, 0, 0.1);
  color: #ffa800;
  border: 1px solid #ffa800;
}
.ant-btn.wait-sign {
  background: rgba(23, 107, 135, 0.1);
  color: #176b87;
  border: 1px solid #176b87;
}
.ant-btn.signed {
  background: rgba(101, 39, 190, 0.1);
  color: #6527be;
  border: 1px solid #6527be;
}
.ant-btn.to-be-delivered {
  background: rgba(205, 102, 136, 0.1);
  color: #cd6688;
  border: 1px solid #cd6688;
}

// ACTIVE SUCCESS BTN FOR REPLY
.ant-btn.active span {
  display: inline-block;
  position: relative;
}
.ant-btn.active span::before {
  content: '';
  background: #d00f26;
  position: absolute;
  top: -11px;
  left: -19px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

// DATE PICKER
.ant-table .ant-picker {
  width: 130px;
  padding: 0;
  background: #ffffff;
  border: 1px solid #cfd3da;
  border-radius: 6px;
}
.ant-table .ant-picker-focused {
  box-shadow: unset;
  border-color: #8fc045;
}
.ant-table .ant-picker .ant-picker-input {
  padding: 6px 8px;
}
.ant-table .ant-picker .ant-picker-input input,
.ant-table .ant-picker .ant-picker-input input::placeholder {
  font-size: 12px;
}

// INPUT
.ant-table .ant-input {
  width: 130px;
  background: #ffffff;
  border: 1px solid #cfd3da;
  border-radius: 6px;
  font-size: 12px;
  padding: 6px 8px;
}
.ant-table .ant-input::placeholder {
  font-size: 12px;
}

/* Track */
.ant-table-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
.ant-table-content::-webkit-scrollbar-thumb {
  background-color: #222;
  border-radius: 8px;
}

.ant-table-content:hover::-webkit-scrollbar-thumb {
  background-color: #222;
}

// LARGE TABLE
.large-table .ant-table-content thead tr th {
  font-size: 12px;
}
.large-table .ant-table-content td {
  font-size: 11px;
}
.large-table .ant-table-tbody > tr > td {
  padding: 12px 12px;
}

@media (max-width: 1199.98px) {
  .ant-table-content thead tr th {
    font-size: 12px;
  }
  .ant-table-content td {
    font-size: 11px;
  }
  .ant-table-tbody > tr > td {
    padding: 12px 12px;
  }
}

@media (max-width: 767.98px) {
  .ant-table-tbody > tr > td {
    padding: 6px 12px;
  }
}
