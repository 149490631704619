@import './nav-language';
@import './nav-profile';
@import './sider';
@import './header';
@import './nav-notification';

// DASHBOARD LAYOUT
.dashboard-layout .ant-layout {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}