#main-form-style {
  .large-radio-group {
    .option-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      border: 1px solid #DDD;

      &.active {
        border-color: #8fc045 !important;
      }

      .outer-circle {
        min-width: 20px;
        height: 20px;
        border: 1px solid #AAA;
        border-radius: 50%;
        position: relative;
  
        &.active {
          border-color: #8fc045;
        }
  
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 11px;
          width: 11px;
          border-radius: 50%;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 150ms;
        }
        &.active::after {
          background-color: #8fc045;
        }
      }
    }
    
  }
}
