.ant-drawer-rtl {
  font-family: 'Cairo', sans-serif;
}
.ant-drawer-rtl.know-more .for-whom .details .item svg {
  margin-right: 0 !important;
  margin-left: 24px;
}

.ant-drawer-rtl.know-more .required-papers .item svg {
  margin-right: 0 !important;
  margin-left: 24px;
  transform: rotate(-180deg);
}

@media (max-width: 767.98px) {
  .ant-drawer-rtl.know-more .for-whom .details .item svg {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-drawer-rtl.know-more .required-papers .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
}