.rtl .services-pg .main-services .item .logo {
  margin-right: 0;
  margin-left: 18px;
}

.rtl .services-pg .services-details .item .details div:first-child {
  margin-right: 0;
  margin-left: 22px;
}
.rtl .services-pg .services-details .item .details svg {
  margin-right: 0;
  margin-left: 7px;
}
.rtl .services-pg .services-details .item .ant-btn.add svg {
  margin-left: 0;
  margin-right: 25px;
  transform: rotate(-180deg);
}

@media (max-width: 1199.98px) {
  // MAIN SERVICES
  .rtl .services-pg .main-services .item .logo {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .services-pg .services-details .item .details div:first-child {
    margin-right: 0;
    margin-left: 16px;
  }
  .rtl .services-pg .services-details .item .details svg {
    margin-right: 0;
    margin-left: 4px;
  }
  .rtl .services-pg .services-details .item .ant-btn.add svg {
    margin-left: 0;
    margin-right: 12px;
  }
}