.profile-form .ant-upload {
  width: 100%;
}
.profile-form .ant-upload .upload {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 186px;
  max-width: 186px;
  margin: auto;
  color: #FFF;
  border-radius: 8px;
  overflow: hidden;
  font-weight: 700;
  font-size: 14px;
  position: relative;
  transform: translateY(5px);
}
.profile-form .ant-upload .upload .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 2;
}
.profile-form .ant-upload svg {
  margin-bottom: 8px;
}
.profile-form .ant-upload .upload .inner {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}