.thanks-dashboard {
  margin: 20px 30px;
}

@media (max-width: 1199.98px) {
  .thanks-dashboard {
    margin: 16px 16px;
  }
}

// ALL thanks-content
.thanks-content {
  margin-top: 40px;
}
.thanks-content .content-item .header > div {
  display: flex;
  align-items: center;
}
.thanks-content .content-item .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.thanks-content .content-item .header h3 {
  color: #262f39;
  font-weight: 600;
  font-size: 20px;
}
.thanks-content .content-item .header p {
  color: #8fc045;
  font-weight: 600;
  font-size: 14px;
  margin: 0 10px;
}

// BODY
.thanks-content .content-item .body {
  margin-bottom: 48px;
}
.thanks-content .content-item .body .body-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.thanks-content .content-item .body h5 {
  color: #8fc045;
  font-weight: 600;
  font-size: 22px;
}
.thanks-content .content-item .body .inner {
  background: rgba(143, 192, 69, 0.1);
  border-radius: 8px;
  padding: 24px;
}
.thanks-content .content-item .body .inner .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.thanks-content .content-item .body .inner .item:not(:last-child) {
  margin-bottom: 20px;
}
.thanks-content .content-item .body .inner p {
  color: #5c5c5c;
}
