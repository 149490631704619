.ant-dropdown {
  border-radius: 8px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.ant-dropdown-menu {
  padding: 0;
}
.ant-dropdown-menu-item {
  padding: 0;
}
.ant-dropdown-menu-item:hover {
  background: rgba(143, 192, 69, 0.10)
}