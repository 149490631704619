.rtl .service-pg .for-whom .details .item svg {
  margin-right: 0;
  margin-left: 24px;
}

.rtl .service-pg .required-papers .item svg {
  margin-right: 0;
  margin-left: 24px;
  transform: rotate(-180deg);
}

@media (max-width: 575.98px) {
  .rtl .service-pg .for-whom .details .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .service-pg .required-papers .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .rtl .service-pg .for-whom .details .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .service-pg .required-papers .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .rtl .service-pg .for-whom .details .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .service-pg .required-papers .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
}