.rtl.App,
.ant-modal-wrap-rtl,
.ant-dropdown,
.ant-select-dropdown-rtl {
  font-family: 'Cairo', sans-serif;
}

.rtl .ant-btn.add-new svg {
  margin-left: 16px;
  margin-right: 0;
}

@import './layout-components/layout-components';
@import './shared-components/shared-components';
@import './pages/pages';