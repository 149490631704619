.form .ant-input,
.profile-form .ant-input,
.profile-form .ant-input-password {
  background: #F5F5F5;
  border-radius: 5px;
  padding: 18px 16px;
  border: 1px solid #F5F5F5; 
  color: #222;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
}
.profile-form .ant-input-password {
  padding: 18px 16px 18px 8px;
}
.profile-form .ant-input-affix-wrapper input.ant-input {
  background: #F5F5F5;
}
.profile-form .ant-input::placeholder {
  color: #666666;
}
.ant-input:focus, .ant-input-focused {
  box-shadow: unset;
  border: 1px solid #8FC045;
}