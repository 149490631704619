.rtl .services-pg.services-dashboard .services-details .item .details div:first-child {
  margin-right: 0;
  margin-left: 10px;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .rtl .services-pg.services-dashboard .main-services .item .logo {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .services-pg.services-dashboard .services-details .item .ant-btn.add svg {
    margin-left: 0;
    margin-right: 8px;
  }
}