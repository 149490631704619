#main-form-style {
  .free-text {
    label {
      font-size: 0.875rem;
      color: #6d6d6d;
      margin-bottom: 0.5rem;
    }

    img.flag {
      width: 1.75rem;
      margin-bottom: 0.5rem;
    }

    textarea {
      background: rgba(221, 221, 221, 0.1);
    }
  }
}
