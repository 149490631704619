.profile-pg {
  margin: 60px 30px 0;
}
.profile-pg h2 {
  color: #484848;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 30px;
}

.profile-pg h4 {
  color: #262F39;
  text-align: center;
  margin-bottom: 24px;
}

.profile-pg .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.profile-pg .header h4 {
  margin-bottom: 0;
}
.profile-pg .header .buttons {
  margin-top: 0;
}

// QUESTION ANSWER DISPLAY
.question-answer {
  margin-top: 20px;
  border: 1px solid #C8C8C8;
  border-radius: 10px;
  padding: 16px 30px 16px;
}
.question-answer .question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.question-answer .question h5 {
  color: #8FC045;
}

// BUTTONS
.profile-pg .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}
.profile-pg .buttons .ant-btn {
  border-radius: 8px;
  width: 118px;
  padding: 14px 0;
  font-weight: 600;
  font-size: 16px;
  height: unset;
  border: unset;
  box-shadow: unset;
}
.profile-pg .buttons .ant-btn.cancel {
  color: #9FA1A6;
}
.profile-pg .buttons .ant-btn.cancel.active {
  color: #262F39;
}
.profile-pg .buttons .ant-btn.save {
  background: #8FC045;
  color: #FFFFFF;
  margin-left: 8px;
}
.profile-pg .buttons .ant-btn.save:hover {
  background: #77aa2a;
}
