.ant-btn.add-new {
  background: #8fc045;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;
  padding: 16px 0;
  border: unset;
  height: unset;
  width: 219px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-filters .ant-btn.add-new {
  height: 60px;
}

.ant-btn.add-new svg {
  margin-right: 10px;
}

.ant-btn.add-new:hover {
  background: #77aa2a;
}

.main-card {
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  margin: 24px 30px 150px;
  padding: 27px 0 40px;
}

.main-card .ant-segmented {
  width: 50%;
  margin: auto;
  margin-bottom: 40px;
}

.ant-empty-description {
  color: #9fa2b4;
}

@media (max-width: 575.98px) {
  .main-card {
    border-radius: 4px;
    margin: 0 16px 150px;
    padding: 16px 0 16px;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .main-card {
    border-radius: 6px;
    margin: 0 16px 150px;
    padding: 24px 0 24px;
  }
  .ant-btn.add-new {
    font-size: 12px;
    border-radius: 6px;
    padding: 16px 0;
    width: 219px;
  }
  .table-filters .ant-btn.add-new {
    height: 45px;
  }
  .ant-btn.add-new svg {
    width: 12px;
    margin-right: 12px;
  }
}
.blog-pg {
  padding: 40px 0 80px;
}

.blog-pg h3 {
  color: #262f39;
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 40px;
}

.blog-pg .item {
  background: #ffffff;
  border: 0.81862px solid #d9d9d9;
  border-radius: 4.0931px;
  overflow: hidden;
  cursor: pointer;
}

.blog-pg .item .image {
  height: 173px;
  background-size: cover;
  background-position: center;
}

.blog-pg .body {
  padding: 14px 20px 30px;
  height: 209px;
  overflow: hidden;
}

.blog-pg .body .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}

.blog-pg .body .title h6 {
  color: #262f39;
}

.blog-pg .body .title .date {
  color: #8fc045;
}

.blog-pg .body h4.question {
  color: #8fc045;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}

.blog-pg .body p.answer {
  color: #727272;
  font-size: 14px;
  line-height: 26px;
}

.blog-pg .item .actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1199.98px) {
  .blog-pg {
    padding: 30px 0;
  }
  .blog-pg h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .blog-pg .item .image {
    height: 173px;
  }
  .blog-pg .body {
    padding: 14px 16px 14px;
    height: unset;
    overflow: hidden;
  }
  .blog-pg .body .title {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .blog-pg .body h4.question {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .blog-pg .body p.answer {
    font-size: 12px;
    line-height: 26px;
  }
  .blog-pg .item .actions {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .blog-pg {
    padding: 40px 0;
  }
  .blog-pg .item .image {
    height: 260px;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .blog-pg .item .image {
    height: 173px;
  }
  .blog-pg .body {
    height: 160px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .blog-pg {
    padding: 40px 0 60px 0;
  }
  .blog-pg .body h4.question {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .blog-pg .body {
    height: 180px;
  }
}
.blog-details {
  padding: 40px 0 100px;
}

.blog-details .main-img {
  height: 280px;
  background-size: cover;
  background-position: center;
  margin-bottom: 30px;
  border-radius: 14px;
}

.blog-details .main h6 {
  color: #262f39;
  font-weight: 600;
  font-size: 19px;
  margin-bottom: 30px;
}

.blog-details .main h4 {
  color: #8fc045;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 24px;
}

.blog-details .list {
  background: rgba(143, 192, 69, 0.1);
  border-radius: 8px;
  padding: 35px;
  margin-bottom: 32px;
}

.blog-details .list .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5c5c5c;
  cursor: pointer;
}

.blog-details .list .item:not(:last-child) {
  margin-bottom: 20px;
}

.blog-details .list .item .content svg {
  margin-right: 24px;
}

.blog-details .index-info:not(:last-child) {
  margin-bottom: 30px;
}

.blog-details .index-info .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.blog-paragraph-title {
  margin-bottom: 24px;
}

.blog-paragraph-title h1,
.blog-paragraph-title h2,
.blog-paragraph-title h3,
.blog-paragraph-title h4,
.blog-paragraph-title h5,
.blog-paragraph-title h6 {
  color: #8fc045;
  font-weight: 700;
  font-size: 20px;
}

.blog-details .blog-paragraph-title {
  margin-bottom: 0;
}

.blog-details .index-info h4 {
  margin-bottom: 0;
}

.blog-details .index-info p {
  color: #262f39;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 12px;
}

.blog-details .index-info ul {
  margin-left: 30px;
  margin-bottom: 12px;
}

.blog-details .index-info li {
  list-style-type: disc;
  line-height: 30px;
}

.blog-details .index-info a {
  color: #8fc045;
}

.blog-details .index-info img {
  max-width: 100%;
  border-radius: 5px;
  margin-top: 12px;
}

.blog-sidebar {
  background: rgba(143, 192, 69, 0.1);
  border-radius: 8px;
  padding: 32px 16px 8px;
}

.blog-sidebar h4 {
  color: #262f39;
  font-weight: 600;
  font-size: 19px;
}

.blog-sidebar .item {
  padding: 24px 0;
  cursor: pointer;
}

.blog-sidebar .item:not(:last-child) {
  border-bottom: 1px solid #cfcfcf;
}

.blog-sidebar .item .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog-sidebar .item .header svg {
  transition: 0.2s linear;
}

.blog-sidebar .item .header.active svg {
  transform: rotate(-180deg);
}

.blog-sidebar .header li {
  font-size: 19px;
  list-style-type: disc;
  color: #8fc045;
}

.blog-sidebar .header li span {
  font-size: 16px;
  color: #262f39;
  font-weight: 500;
}

.blog-sidebar .item .body {
  padding: 10px 0 0 24px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
}

.blog-sidebar .item .body.active {
  max-height: 2000px;
  transition: max-height 0.25s ease-out;
}

.blog-sidebar .body li {
  font-size: 14px;
  color: #262f39;
  font-weight: 500;
  line-height: 29px;
  cursor: pointer;
  transition: 0.2s;
}

.blog-sidebar .body li:hover {
  color: #8fc045;
}

.blog-sidebar .body li:not(:last-child) {
  margin-bottom: 8px;
}

.blog-sidebar .new-blogs {
  font-size: 14px;
  color: #262f39;
  font-weight: 500;
  margin: 16px 0;
}

.blog-sidebar .new-blogs li {
  list-style-type: disc;
  line-height: 29px;
  cursor: pointer;
  transition: 0.2s;
}

.blog-sidebar .new-blogs li:hover {
  color: #8fc045;
}

@media (max-width: 991.98px) {
  .blog-details {
    padding: 30px 0;
  }
  .blog-details .main-img {
    height: 130px;
    margin-bottom: 12px;
    border-radius: 5px;
  }
  .blog-details .main h6 {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .blog-details .main h4 {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .blog-details .list {
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 20px;
  }
  .blog-details .list .item {
    font-size: 12px;
  }
  .blog-details .list .item:not(:last-child) {
    margin-bottom: 10px;
  }
  .blog-details .list .item .content svg {
    margin-right: 10px;
    width: 15px;
  }
  .blog-details .index-info:not(:last-child) {
    margin-bottom: 20px;
  }
  .blog-details .index-info .title {
    font-size: 14px;
    margin-bottom: 12px;
  }
  .blog-details .index-info h4 {
    margin-bottom: 0;
  }
  .blog-details .index-info p {
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 6px;
  }
  .blog-details .index-info ul {
    margin-left: 20px;
    margin-bottom: 12px;
  }
  .blog-details .index-info li {
    font-size: 12px;
    line-height: 22px;
  }
  .blog-details .index-info img {
    max-width: 100%;
    border-radius: 5px;
    margin-top: 6px;
  }
  .blog-sidebar {
    border-radius: 5px;
    padding: 24px 16px 8px;
  }
  .blog-sidebar h4 {
    font-size: 16px;
  }
  .blog-sidebar .item {
    padding: 12px 0;
  }
  .blog-sidebar .header li {
    font-size: 14px;
  }
  .blog-sidebar .header li span {
    font-size: 12px;
  }
  .blog-sidebar .item .body {
    padding: 10px 0 0 12px;
  }
  .blog-sidebar .body li {
    font-size: 12px;
    line-height: 22px;
  }
  .blog-sidebar .new-blogs {
    font-size: 12px;
  }
  .blog-sidebar .new-blogs li {
    line-height: 22px;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .blog-details {
    padding: 30px 0;
  }
  .blog-details .main-img {
    height: 150px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .blog-details {
    padding: 40px 0 80px 0;
  }
  .blog-details .main-img {
    height: 200px;
    margin-bottom: 24px;
    border-radius: 10px;
  }
  .blog-details .main h6 {
    font-size: 14px;
    margin-bottom: 14px;
  }
  .blog-details .main h4 {
    font-size: 18px;
    margin-bottom: 24px;
  }
  .blog-details .list {
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 20px;
  }
  .blog-details .list .item {
    font-size: 14px;
  }
  .blog-details .list .item:not(:last-child) {
    margin-bottom: 10px;
  }
  .blog-details .list .item .content svg {
    margin-right: 14px;
    width: 18px;
  }
  .blog-details .index-info:not(:last-child) {
    margin-bottom: 24px;
  }
  .blog-details .index-info .title {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .blog-details .index-info h4 {
    margin-bottom: 0;
  }
  .blog-details .index-info p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 8px;
  }
  .blog-details .index-info ul {
    margin-left: 24px;
    margin-bottom: 12px;
  }
  .blog-details .index-info li {
    font-size: 14px;
    line-height: 26px;
  }
  .blog-details .index-info img {
    max-width: 100%;
    border-radius: 5px;
    margin-top: 6px;
  }
  .blog-sidebar {
    border-radius: 5px;
    padding: 24px 16px 8px;
  }
  .blog-sidebar h4 {
    font-size: 16px;
  }
  .blog-sidebar .item {
    padding: 12px 0;
  }
  .blog-sidebar .header li {
    font-size: 14px;
  }
  .blog-sidebar .header li span {
    font-size: 12px;
  }
  .blog-sidebar .item .body {
    padding: 10px 0 0 12px;
  }
  .blog-sidebar .body li {
    font-size: 12px;
    line-height: 22px;
  }
  .blog-sidebar .new-blogs {
    font-size: 12px;
  }
  .blog-sidebar .new-blogs li {
    line-height: 22px;
  }
}
.thanks-dashboard {
  margin: 20px 30px;
}

@media (max-width: 1199.98px) {
  .thanks-dashboard {
    margin: 16px 16px;
  }
}
.thanks-content {
  margin-top: 40px;
}

.thanks-content .content-item .header > div {
  display: flex;
  align-items: center;
}

.thanks-content .content-item .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.thanks-content .content-item .header h3 {
  color: #262f39;
  font-weight: 600;
  font-size: 20px;
}

.thanks-content .content-item .header p {
  color: #8fc045;
  font-weight: 600;
  font-size: 14px;
  margin: 0 10px;
}

.thanks-content .content-item .body {
  margin-bottom: 48px;
}

.thanks-content .content-item .body .body-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.thanks-content .content-item .body h5 {
  color: #8fc045;
  font-weight: 600;
  font-size: 22px;
}

.thanks-content .content-item .body .inner {
  background: rgba(143, 192, 69, 0.1);
  border-radius: 8px;
  padding: 24px;
}

.thanks-content .content-item .body .inner .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.thanks-content .content-item .body .inner .item:not(:last-child) {
  margin-bottom: 20px;
}

.thanks-content .content-item .body .inner p {
  color: #5c5c5c;
}

.profile-pg {
  margin: 60px 30px 0;
}

.profile-pg h2 {
  color: #484848;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 30px;
}

.profile-pg h4 {
  color: #262F39;
  text-align: center;
  margin-bottom: 24px;
}

.profile-pg .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.profile-pg .header h4 {
  margin-bottom: 0;
}

.profile-pg .header .buttons {
  margin-top: 0;
}

.question-answer {
  margin-top: 20px;
  border: 1px solid #C8C8C8;
  border-radius: 10px;
  padding: 16px 30px 16px;
}

.question-answer .question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.question-answer .question h5 {
  color: #8FC045;
}

.profile-pg .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}

.profile-pg .buttons .ant-btn {
  border-radius: 8px;
  width: 118px;
  padding: 14px 0;
  font-weight: 600;
  font-size: 16px;
  height: unset;
  border: unset;
  box-shadow: unset;
}

.profile-pg .buttons .ant-btn.cancel {
  color: #9FA1A6;
}

.profile-pg .buttons .ant-btn.cancel.active {
  color: #262F39;
}

.profile-pg .buttons .ant-btn.save {
  background: #8FC045;
  color: #FFFFFF;
  margin-left: 8px;
}

.profile-pg .buttons .ant-btn.save:hover {
  background: #77aa2a;
}

.services-dashboard {
  margin: 20px 30px;
}

.services-pg.services-dashboard {
  padding: 0;
}

.services-pg.services-dashboard .services-details .item .details p {
  font-size: 13px;
}

.services-pg.services-dashboard .services-details .item .details div:first-child {
  margin-right: 10px;
}

@media (max-width: 1199.98px) {
  .services-dashboard {
    margin: 16px 16px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .services-pg.services-dashboard .main-services .item .logo {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  .services-pg.services-dashboard .main-services .item .logo svg {
    width: 45px;
  }
  .services-pg.services-dashboard .main-services .item h4 {
    font-size: 18px;
  }
  .services-pg.services-dashboard .services-details .item .details p {
    font-size: 11px;
  }
  .services-pg.services-dashboard .services-details .item .ant-btn.add {
    width: 160px;
    font-size: 12px;
    padding: 14px;
  }
  .services-pg.services-dashboard .services-details .item .ant-btn.more {
    font-size: 12px;
  }
  .services-pg.services-dashboard .services-details .item .ant-btn.add svg {
    margin-left: 8px;
  }
}
.dashboard-pg {
  margin: 50px 30px 0;
}

.dashboard-pg .item {
  border: 0.723777px solid #afafaf;
  border-radius: 7.23777px;
  padding: 30px 10px 30px 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dashboard-pg .item svg {
  width: 55px;
  height: 55px;
  margin-right: 40px;
  transition: 0.3s all ease-in-out;
}

.dashboard-pg .item:hover svg {
  transform: scale(1.05, 1.05);
}

.dashboard-pg .item h5 {
  color: #484848;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 14px;
}

.dashboard-pg .item p {
  color: #a0a0a0;
  font-weight: 500;
  font-size: 15px;
}

.dashboard-pg .item p span {
  color: #484848;
  font-weight: 700;
  font-size: 30px;
  margin-right: 12px;
}

.request-status-card {
  background-color: #FFF;
  border: 1px solid #DDD;
  border-radius: 0.75rem;
  padding: 1rem;
  cursor: pointer;
}
.request-status-card:hover {
  border: 1px solid transparent;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
}

@media (max-width: 991.98px) {
  .dashboard-pg {
    margin: 16px 12px 0;
  }
  .dashboard-pg .item {
    padding: 20px 10px 20px 20px;
  }
  .dashboard-pg .item svg {
    margin-right: 25px;
  }
  .dashboard-pg .item h5 {
    color: #484848;
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 14px;
  }
  .dashboard-pg .item p {
    color: #a0a0a0;
    font-weight: 500;
    font-size: 15px;
  }
  .dashboard-pg .item p span {
    color: #484848;
    font-weight: 700;
    font-size: 30px;
    margin-right: 12px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .dashboard-pg .item {
    padding: 20px 10px 20px 20px;
  }
  .dashboard-pg .item svg {
    margin-right: 25px;
  }
  .dashboard-pg .item p span {
    font-size: 28px;
    margin-right: 12px;
  }
}
.nav-language {
  border-radius: 5px;
  position: relative;
}

.nav-language img {
  width: 25px;
}

.nav-language .header > div {
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s all;
}

.nav-language.open .header > div {
  background: #CDCDCD;
}

.nav-language img,
.nav-language .language {
  margin-right: 16px;
}

.nav-language .language {
  color: #262F39;
  font-weight: 500;
}

.nav-language .header svg {
  transition: 0.3s linear;
}

.nav-language.open .header svg path {
  fill: #484848;
}

.nav-language.open .header svg {
  transform: rotate(-180deg);
}

.nav-language .dropdown {
  position: absolute;
  bottom: -2px;
  left: 0;
  padding: 8px 4px 8px;
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 100%;
  transform: translateY(100%);
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: 0.3s linear;
  z-index: 9;
}

.nav-language.open .dropdown {
  height: 78px;
  opacity: 1;
}

.nav-language .dropdown .language {
  cursor: pointer;
}

.nav-language .dropdown .language:not(:last-child) {
  margin-bottom: 10px;
}

.header-nav .nav-language {
  margin-right: 0;
}

.header-nav .nav-language .header svg path {
  fill: #484848;
}

.header-nav .nav-language .language {
  margin-right: 11px;
}

@media (max-width: 991.98px) {
  .nav-language {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .header-nav .nav-language {
    display: block;
  }
  .nav-language img {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .nav-language {
    margin-right: 20px;
  }
  .nav-language .flag,
  .nav-language .language {
    margin-right: 10px;
  }
}
.ant-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-sider {
  background: #262f39;
  min-width: 255px !important;
  height: 100vh;
  padding: 34px 0;
  overflow-y: auto;
  position: relative;
}

.ant-layout-sider .logo {
  width: 116px;
  margin: auto;
  margin-bottom: 60px;
}

.ant-layout-sider .logo img {
  width: 100%;
}

.ant-layout-sider .ant-btn.new-request {
  background: #8fc045;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;
  padding: 16px 0;
  border: unset;
  height: unset;
  width: 219px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 40px;
}

.ant-layout-sider .ant-btn.new-request svg {
  margin-right: 16px;
}

.ant-layout-sider .ant-btn.new-request:hover {
  background: #77aa2a;
}

.ant-menu {
  background: #262f39;
  padding-bottom: 32px;
}

.ant-menu-submenu-title:active,
.ant-menu-sub.ant-menu-inline {
  background: #262f39;
}

.ant-menu-item-active {
  background: rgba(159, 162, 180, 0.08) !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: unset;
}

.menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: unset !important;
  transform: scaleY(0) !important;
}

.ant-menu-inline .ant-menu-item {
  width: 100%;
  margin-top: 0;
}

.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
  height: 56px !important;
  line-height: 56px !important;
}

.ant-menu-inline > .ant-menu-item {
  height: 56px;
  line-height: 56px;
}

.ant-menu-title-content a,
.ant-menu-title-content a:hover,
.ant-menu-title-content {
  color: #ffffff;
  font-size: 16px;
}

.ant-menu-inline .ant-menu-submenu svg,
.ant-menu-inline.ant-menu-root .ant-menu-item svg {
  width: 24px;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 19px;
  color: #fff;
}

.ant-menu-submenu-active .ant-menu-submenu-arrow,
.ant-menu-submenu .ant-menu-submenu-arrow {
  color: #ffffff !important;
}

.ant-menu-item-disabled .ant-menu-title-content {
  color: #828282;
}

.ant-menu-item-disabled.ant-menu-item:active,
.ant-menu-item.active {
  background: rgba(159, 162, 180, 0.08) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgba(159, 162, 180, 0.08);
}

.ant-menu-item.active .ant-menu-title-content {
  color: #8fc045;
  font-weight: 600;
}

.ant-menu-item.active .anticon,
.ant-menu-submenu-selected .anticon {
  color: #8fc045;
}

.ant-layout-sider hr {
  border-top: 1px solid #4d4d4d;
  opacity: 1;
  margin-bottom: 0;
  margin-top: 48px;
}

.ant-layout-sider .close-icon {
  position: absolute;
  top: 27px;
  right: 20px;
  width: 12px;
  cursor: pointer;
  display: none;
}

.ant-menu-item.logout {
  margin-top: 30px;
  border-top: 1px solid #4d4d4d;
  height: unset;
  padding: 20px 0;
}

.ant-layout-sider::-webkit-scrollbar {
  width: 0px;
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
}

.ant-layout-sider:hover::-webkit-scrollbar {
  width: 0px;
}

.ant-layout-sider::-webkit-scrollbar-track {
  border-radius: 10px;
}

.ant-layout-sider.ant-layout-sider-collapsed {
  min-width: unset !important;
}

.ant-layout-sider.ant-layout-sider-collapsed .logo {
  width: 67px;
  margin-bottom: 40px;
}

.ant-layout-sider.ant-layout-sider-collapsed .ant-btn.new-request {
  width: 80%;
}

.ant-layout-sider.ant-layout-sider-collapsed .ant-btn.new-request span {
  display: none;
}

.ant-layout-sider.ant-layout-sider-collapsed .ant-btn.new-request svg {
  margin: 0;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ant-menu-submenu-popup {
  background-color: transparent;
}

.ant-menu-submenu-popup .ant-menu-title-content {
  color: #262f39;
}

@media (max-width: 991.98px) {
  .ant-layout-sider {
    transform: translateX(-100%);
    position: absolute;
    z-index: 9999;
  }
  .ant-layout-sider.nav-open {
    transform: translateX(0%);
  }
  .ant-layout-sider .close-icon {
    display: block;
  }
}
.ant-layout-header {
  height: unset;
  line-height: unset;
  background: #fff;
  padding: 32px 30px 23px;
}

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-nav .left,
.header-nav .right {
  display: flex;
  align-items: center;
}

.header-nav h4 {
  color: #252733;
  font-weight: 600;
  font-size: 23px;
  margin-right: 40px;
}

.header-nav .toggler {
  display: none;
  margin-right: 20px;
  cursor: pointer;
}

.menu-breadcrumb {
  cursor: pointer;
  margin-right: 16px;
  font-size: 30px;
  color: #252733;
}

@media (max-width: 991.98px) {
  .ant-layout-header {
    padding: 11px 16px 24px;
  }
  .header-nav h4 {
    font-size: 14px;
    margin-right: unset;
  }
  .header-nav .toggler {
    display: block;
  }
  .menu-breadcrumb {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ant-layout-header {
    padding: 16px 16px 30px;
  }
  .header-nav h4 {
    font-size: 16px;
    margin-right: unset;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .ant-layout-header {
    padding: 24px 16px;
  }
  .header-nav h4 {
    font-size: 16px;
    margin-right: unset;
  }
}
.nav-notifications {
  margin: 0 2.5rem 0 1.25rem;
}

.nav-notifications.unseen {
  position: relative;
  cursor: pointer;
}

.nav-notifications.unseen::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #8fc045;
  border: 1.5px solid #f7f8fc;
}

.nav-notifications {
  cursor: pointer;
}

.ant-dropdown .nav-notification {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 320px;
}

.ant-dropdown .nav-notification .nav-notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid #e6ebf1;
}

.ant-dropdown .nav-notification .nav-notification-header h4 {
  color: #252733;
  font-size: 17px;
  font-weight: 600;
}

.ant-dropdown .nav-notification .ant-btn {
  color: #8fc045;
  font-weight: 500;
  border: unset;
  height: unset;
  box-shadow: unset;
}

.nav-notification-body {
  padding: 0;
  max-height: 300px;
  overflow: auto;
}

.nav-notification-body .ant-list-sm .ant-list-item {
  padding: 12px 16px;
}

.nav-notification-body::-webkit-scrollbar {
  width: 2px;
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
}

.nav-notification-body:hover::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.nav-notification-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.nav-notification-body::-webkit-scrollbar-thumb {
  background-color: rgba(143, 192, 69, 0.3);
  border-radius: 8px;
}

.nav-notification-body:hover::-webkit-scrollbar-thumb {
  background-color: rgba(143, 192, 69, 0.3);
}

.ant-list-item.list-clickable.unseen {
  background-color: rgba(143, 192, 69, 0.1);
}

.ant-list-item.list-clickable .title {
  color: #252733;
  font-size: 12px;
  font-weight: 700;
}

.ant-list-item.list-clickable .description {
  color: #727272;
  font-size: 12px;
}

.ant-list-item.list-clickable small {
  min-width: 70px;
  margin-left: 8px;
  font-size: 10px;
  text-align: right;
}

.ant-dropdown .nav-notification .nav-notification-footer {
  padding: 0.65rem;
  text-align: center;
  border-top: 1px solid #e6ebf1;
}

.empty-notification {
  padding: 64px 0;
  text-align: center;
}

.empty-notification p {
  color: #727272;
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
}

@media (max-width: 767.98px) {
  .nav-notifications {
    margin-left: 24px;
  }
}
.dashboard-layout .ant-layout {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-select {
  background: #fff;
  border: 1px solid #cfd3da;
  border-radius: 5px;
  padding: 9px 8px;
  display: block;
  width: 100%;
}

.ant-select-selection-placeholder {
  color: #626d7d;
  font-weight: 400;
  font-size: 14px;
}

.ant-select.ant-select-focused .ant-select-selector {
  box-shadow: unset !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: unset;
  color: #262f39;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 1199.98px) {
  .ant-select {
    padding: 7px 6px;
  }
  .ant-select-selection-placeholder {
    font-size: 12px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 12px;
  }
  .ant-select svg {
    width: 10px;
  }
}
.ant-picker-dropdown {
  z-index: 99992;
}

.table-filters .ant-picker {
  background: #e3e3e3;
  border-radius: 5px;
  border: unset;
  width: 100%;
  padding: 18px 16px;
}

.table-filters .ant-picker-input input::-moz-placeholder {
  color: #656565;
  font-weight: 500;
  font-size: 14px;
}

.table-filters .ant-picker-input input::placeholder {
  color: #656565;
  font-weight: 500;
  font-size: 14px;
}

.table-filters .ant-picker-input input {
  color: #262f39;
  font-weight: 600 !important;
}

.table-filters .ant-picker-focused {
  border-color: unset;
  box-shadow: unset;
  outline: 0;
}

.table-filters .ant-picker:hover,
.table-filters .ant-picker-focused {
  border-color: unset;
}

.ant-picker-clear {
  background: transparent;
  width: 44px;
}

@media (max-width: 1199.98px) {
  .table-filters .ant-picker {
    border-radius: 5px;
    padding: 12px 16px;
  }
  .table-filters .ant-picker-input input {
    font-size: 12px;
  }
  .table-filters .ant-picker-input input::-moz-placeholder {
    font-size: 12px;
  }
  .table-filters .ant-picker-input input::placeholder {
    font-size: 12px;
  }
  .table-filters .ant-picker-suffix svg {
    width: 20px;
  }
}
.table-filters .ant-select {
  background: #e3e3e3;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 13px 8px;
  display: block;
  width: 100%;
}

.table-filters .ant-select-selection-placeholder {
  color: #656565;
  font-weight: 500;
  font-size: 14px;
}

.table-filters .ant-select.ant-select-focused .ant-select-selector {
  box-shadow: unset !important;
}

.table-filters .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: unset;
  color: #262f39;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 1199.98px) {
  .table-filters .ant-select {
    padding: 7px 6px;
  }
  .table-filters .ant-select-selection-placeholder {
    font-size: 12px;
  }
  .table-filters .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 12px;
  }
  .table-filters .ant-select svg {
    width: 10px;
  }
}
.search .ant-input-affix-wrapper {
  background: #E3E3E3;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  padding: 18px;
  color: #262F39;
  font-size: 14px;
  font-weight: 600;
}

.search input::-moz-placeholder {
  color: #494949;
  font-size: 14px;
  font-weight: 400;
}

.search input::placeholder {
  color: #494949;
  font-size: 14px;
  font-weight: 400;
}

.search .ant-input-affix-wrapper input {
  background-color: #E3E3E3 !important;
  padding-right: 12px;
}

.search .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  box-shadow: unset;
  border: 1px solid #262F39 !important;
}

.search .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid #262F39;
}

.search .ant-input-suffix svg {
  width: 24px;
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .search .ant-input-affix-wrapper {
    border-radius: 5px;
    padding: 12px;
    color: #262F39;
    font-size: 12px;
  }
  .search input::-moz-placeholder {
    font-size: 12px;
  }
  .search input::placeholder {
    font-size: 12px;
  }
}
.table-search .ant-input-affix-wrapper {
  padding: 0.875rem 1rem 0.875rem;
  background: rgba(143, 192, 69, 0.1);
  border: 1px solid #8fc045;
  border-radius: 5px;
}

.table-search .ant-input-affix-wrapper input.ant-input {
  background-color: transparent;
  padding-left: 0;
}

.table-filters {
  margin: 0 30px 50px;
}

.table-filters label {
  display: block;
  color: #262F39;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 14px;
}

@media (max-width: 1199.98px) {
  .table-filters {
    margin: 0 16px 30px;
  }
  .table-filters label {
    font-size: 12px;
    margin-bottom: 6px;
  }
}
.profile-form .ant-upload {
  width: 100%;
}

.profile-form .ant-upload .upload {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 186px;
  max-width: 186px;
  margin: auto;
  color: #FFF;
  border-radius: 8px;
  overflow: hidden;
  font-weight: 700;
  font-size: 14px;
  position: relative;
  transform: translateY(5px);
}

.profile-form .ant-upload .upload .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 2;
}

.profile-form .ant-upload svg {
  margin-bottom: 8px;
}

.profile-form .ant-upload .upload .inner {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.form .ant-input,
.profile-form .ant-input,
.profile-form .ant-input-password {
  background: #F5F5F5;
  border-radius: 5px;
  padding: 18px 16px;
  border: 1px solid #F5F5F5;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
}

.profile-form .ant-input-password {
  padding: 18px 16px 18px 8px;
}

.profile-form .ant-input-affix-wrapper input.ant-input {
  background: #F5F5F5;
}

.profile-form .ant-input::-moz-placeholder {
  color: #666666;
}

.profile-form .ant-input::placeholder {
  color: #666666;
}

.ant-input:focus, .ant-input-focused {
  box-shadow: unset;
  border: 1px solid #8FC045;
}

.file-upload .ant-upload.ant-upload-select {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #CFD3DA;
  border-radius: 6px;
  padding: 10px 8px;
}

.file-upload span.ant-upload {
  color: #626D7D;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-upload span.ant-upload svg path {
  stroke: #8FC045;
}

.ant-upload-list.ant-upload-list-text {
  display: none;
}

.icon-upload {
  display: block;
  width: 200px;
  margin: auto;
  margin-bottom: 16px;
  text-align: center;
}

.icon-upload .upload {
  border: 1px dashed #8fc045;
  color: #8fc045 !important;
}

.icon-upload .upload .content svg path {
  fill: #8fc045;
}

.profile-form .ant-select {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 13px 8px;
  display: block;
  width: 100%;
}

.profile-form .ant-select-selection-placeholder {
  color: #656565;
  font-weight: 500;
  font-size: 14px;
}

.profile-form .ant-select.ant-select-focused .ant-select-selector {
  box-shadow: unset !important;
}

.profile-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: unset;
  color: #262f39;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
}

.ant-input-number-group-addon {
  background: #f5f5f5;
  border: unset;
  border-radius: 5px;
  border-left: 1px solid rgba(38, 47, 57, 0.2) !important;
}

.ant-input-number-group-addon .ant-select.ant-select-single.ant-select-show-arrow {
  width: 90px;
}

@media (max-width: 1199.98px) {
  .profile-form .ant-select {
    padding: 7px 6px;
  }
  .profile-form .ant-select-selection-placeholder {
    font-size: 12px;
  }
  .profile-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 12px;
  }
  .profile-form .ant-select svg {
    width: 10px;
  }
}
.ant-picker-dropdown {
  z-index: 99992;
}

.profile-form .ant-picker {
  background: #F5F5F5;
  border-radius: 5px;
  border: unset;
  width: 100%;
  padding: 18px 16px;
}

.profile-form .ant-picker-input input::-moz-placeholder {
  color: #656565;
  font-weight: 500;
  font-size: 14px;
}

.profile-form .ant-picker-input input::placeholder {
  color: #656565;
  font-weight: 500;
  font-size: 14px;
}

.profile-form .ant-picker-input input {
  color: #262f39;
  font-weight: 600 !important;
}

.profile-form .ant-picker-focused {
  border-color: unset;
  box-shadow: unset;
  outline: 0;
}

.profile-form .ant-picker:hover,
.profile-form .ant-picker-focused {
  border-color: unset;
}

.ant-picker-clear {
  background: transparent;
  width: 44px;
}

@media (max-width: 1199.98px) {
  .profile-form .ant-picker {
    border-radius: 5px;
    padding: 12px 16px;
  }
  .profile-form .ant-picker-input input {
    font-size: 12px;
  }
  .profile-form .ant-picker-input input::-moz-placeholder {
    font-size: 12px;
  }
  .profile-form .ant-picker-input input::placeholder {
    font-size: 12px;
  }
  .profile-form .ant-picker-suffix svg {
    width: 20px;
  }
}
.profile-form {
  border: 1px solid #c8c8c8;
  border-radius: 13px;
  padding: 39px 30px 35px;
}

.profile-form .label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-form label {
  color: #262f39;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 12px;
}

.profile-form .label .ant-btn {
  color: #8fc045;
  font-weight: 600;
  font-size: 14px;
  border: unset;
  padding: unset;
  box-shadow: unset;
  transform: translateY(-5px);
}

.profile-form .label .ant-btn span {
  text-decoration: underline;
}

label img {
  width: 25px;
  height: 25px;
  margin: 0 8px;
}

.form-type_4 label {
  font-size: 0.875rem;
  color: #6d6d6d;
  margin-bottom: 8px;
}

.form-type_4 label span {
  color: #aaa;
}

.form-type_4 .radio-card {
  background-color: #FFF;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 1rem 1.25rem;
}

.upload-card_2 .inner {
  border-radius: 0.75rem;
  border: 1px solid rgba(143, 192, 69, 0.3);
  background: rgba(143, 192, 69, 0.05);
  padding: 1.875rem 1.5rem;
  text-align: center;
}
.upload-card_2 .inner .image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 9.25rem;
  height: 9.25rem;
  margin: 0 auto 1.875rem;
  border-radius: 50%;
  position: relative;
}
.upload-card_2 .inner .image .icon-cover {
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-card_2 .inner h5 {
  color: #8fc045;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.upload-card_2 .inner p {
  color: #6d6d6d;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.upload-general-card {
  background-color: #FFF;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 1rem;
}

.form-type_4 .ant-input {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
  padding: 1rem 0.875rem;
  font-size: 1rem;
  color: #262f39;
  font-weight: 500;
}

.form-type_4 .ant-input::-moz-placeholder {
  color: #949494;
  font-size: 1rem;
}

.form-type_4 .ant-input::placeholder {
  color: #949494;
  font-size: 1rem;
}

.form-type_4 .ant-input:focus {
  border: 1px solid #8fc045;
}

.form-type_4 .ant-radio-wrapper span.ant-radio + * {
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}

.form-type_4 .ant-radio-wrapper {
  margin-bottom: 0 !important;
}

.form-type_4 .ant-checkbox-wrapper span:nth-child(2) {
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}

.form-type_4 .ant-picker {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  width: 100%;
}

.form-type_4 .ant-picker-focused {
  border: 1px solid #8fc045;
  box-shadow: unset;
}

.form-type_4 .ant-picker-input > input {
  font-size: 1rem;
  color: #262f39;
  font-weight: 500;
}

.form-type_4 .ant-picker-input > input::-moz-placeholder {
  font-size: 1rem;
}

.form-type_4 .ant-picker-input > input::placeholder {
  font-size: 1rem;
}

.form-type_4 .upload-container {
  background: rgba(143, 192, 69, 0.1);
  color: #8fc045;
  font-size: 1rem;
  border: 1px dashed #8fc045;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.01rem;
}

.form-type_4 .upload-container svg {
  width: 1.25rem;
  margin-right: 0.75rem;
}

.form-type_4 .upload-container svg path {
  stroke: #8fc045;
}

.form-type_4 .ant-select {
  padding: 0;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
}

.form-type_4 .ant-select-selection-placeholder {
  color: #949494;
  font-size: 1rem;
}

.form-type_4 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #262f39;
  font-size: 1rem;
  padding: 0.8rem 0.875rem;
  height: 3.6rem;
  line-height: 3.6rem;
}

.form-type_4 .ant-select-item-option-content {
  white-space: normal;
}

@media (max-width: 575.98px) {
  .form-type_4 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0.36rem 1.1rem;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .form-type_4 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0.5rem 0.875rem;
  }
}
@media (min-width: 991.98px) and (max-width: 1199.98px) {
  .form-type_4 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0.5rem 0.875rem;
  }
}
.ant-select-dropdown.form-type_4 {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 0;
}

.ant-select-dropdown.form-type_4 .ant-select-item-option-content {
  color: #262f39;
  font-size: 0.875rem;
  padding: 0.625rem 0.785rem;
  font-weight: 500;
}

.ant-select-dropdown.form-type_4 .ant-select-item {
  padding: 0;
}

.ant-select-dropdown.form-type_4 .ant-select-item:not(:last-child) {
  border-bottom: 1px solid rgba(143, 192, 69, 0.3);
}

.ant-select-dropdown.form-type_4 .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #e3e3e3;
  color: #262f39;
}

.ant-select-dropdown.form-type_4 .ant-select-item-option-selected,
.ant-select-dropdown.form-type_4 .ant-select-item-option-active,
.ant-select-dropdown.form-type_4 .ant-select-item:hover {
  background: #e3e3e3;
  color: #262f39;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-transition: background-color 600000s 0s, color 600000s 0s;
  transition: background-color 600000s 0s, color 600000s 0s;
}

.form .input {
  margin-bottom: 16px;
}

.form label {
  color: #484848;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
}

.form .ant-btn.submit {
  background: #8fc045;
  color: #fff;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  height: 55px;
  width: 100%;
  border: unset;
  margin-top: 10px;
  letter-spacing: 0.1em;
}

.form .ant-btn.submit:hover {
  background: #77aa2a;
}

@media (max-width: 767.98px) {
  .form .input {
    margin-bottom: 12px;
  }
  .form label {
    font-size: 12px;
    margin-bottom: 6px;
  }
  .form .ant-btn.submit {
    border-radius: 4px;
    font-size: 14px;
    height: 50px;
    margin-top: 10px;
  }
}
.ant-modal.modal-xs {
  width: 400px !important;
}

.ant-modal.modal-sm {
  width: 500px !important;
}

.ant-modal.modal-md {
  width: 600px !important;
}

.ant-modal.modal-lg {
  width: 800px !important;
}

.ant-modal.modal-xxl {
  width: 1000px !important;
}

.ant-modal h3 {
  color: #484848;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;
}

.ant-modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.ant-modal .header h3 {
  margin-bottom: 0;
}

.ant-modal .header .close {
  cursor: pointer;
}

.ant-modal .ant-upload-list.ant-upload-list-text {
  display: block;
}

.ant-modal .ant-upload-list .ant-tooltip {
  display: none;
}

.ant-modal .user-name {
  text-align: center;
  margin-bottom: 24px;
}

.ant-modal .user-name .image {
  background-size: contain;
  background-position: center;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 16px;
}

.ant-modal .user-name .name {
  color: #484848;
  font-weight: 600;
  font-size: 20px;
}

.ant-modal h5 {
  color: #8fc045;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}

.ant-modal p.desc {
  color: #262f39;
  font-weight: 600;
}

.ant-modal a.desc {
  display: block;
  font-weight: 600;
  text-decoration: underline;
  line-height: 28px;
}

.ant-modal .form .ant-input {
  padding: 12px 16px;
}

.ant-modal .form .ant-input::-moz-placeholder {
  color: #656565;
}

.ant-modal .form .ant-input::placeholder {
  color: #656565;
}

.ant-modal .buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-modal .buttons .ant-btn {
  color: #484848;
  font-weight: 600;
  border-radius: 8px;
  border: unset;
  height: unset;
  box-shadow: unset;
}

.ant-modal .buttons .ant-btn.submit {
  color: #ffffff !important;
  padding: 10px 30px;
}

.ant-modal .buttons .ant-btn.success {
  background: #8fc045;
}

.ant-modal .buttons .ant-btn.success:hover {
  background: #77aa2a;
}

.ant-modal .buttons .ant-btn.danger {
  background: #fa4458;
}

.ant-modal .buttons .ant-btn.danger:hover {
  background: #ff2f48;
}

.ant-modal .buttons .ant-btn.black {
  background: #262f39;
}

.ant-modal .buttons .ant-btn.black:hover {
  background: #262f39;
}

.ant-modal .buttons .ant-btn.wait-sign {
  background: #176b87;
}

.ant-modal .buttons .ant-btn.wait-sign:hover {
  background: #176b87;
}

.ant-modal .chatbox {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  height: 100%;
  max-height: 333px;
  overflow-y: auto;
  padding: 16px;
  margin-bottom: 16px;
}

.ant-modal .chatbox .single-chat .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.ant-modal .chatbox .single-chat:not(:last-child) {
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 16px;
  margin-bottom: 8px;
}

.ant-modal .chatbox .single-chat .header h6 {
  color: #262f39;
  font-weight: 600;
  font-size: 12px;
}

.ant-modal .chatbox .single-chat .header p {
  color: #5c5c5c;
  font-size: 12px;
}

.ant-modal .chatbox .single-chat .header p span {
  color: #8fc045;
  font-size: 10px;
}

.ant-modal .chatbox .single-chat h6 {
  color: #262f39;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 6px;
}

.ant-modal .chatbox .single-chat .desc {
  font-weight: 300;
  font-size: 11px;
}

.ant-modal .chatbox .single-chat a {
  color: #8fc045;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
}

.ant-modal .chatbox::-webkit-scrollbar {
  width: 6px;
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
}

.ant-modal .chatbox:hover::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.ant-modal .chatbox::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.ant-modal .chatbox::-webkit-scrollbar-thumb {
  background-color: #828282;
  border-radius: 8px;
}

.ant-modal .mising-info:not(:last-child) {
  margin-bottom: 14px;
}

.ant-modal .mising-info li {
  margin-bottom: 10px;
}

.ant-modal .mising-info .info p {
  font-size: 12px;
  line-height: 20px;
}

.ant-modal .mising-info .info p span {
  color: #8fc045;
}

.ant-modal .ant-modal-body .close {
  position: absolute;
  top: 29px;
  right: 35px;
  cursor: pointer;
}

.ant-modal.register-modal {
  margin-top: 50px;
  margin-bottom: 50px;
}

.ant-modal.register-modal .header {
  display: block;
  text-align: center;
  margin-bottom: 24px;
}

.ant-modal.register-modal .header img {
  width: 170px;
  margin-bottom: 24px;
}

.ant-modal.register-modal .header h4 {
  color: #8fc045;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.ant-modal.register-modal .header p {
  color: #262f39;
  font-weight: 500;
  line-height: 28px;
}

.ant-modal.register-modal .header p span {
  color: #8fc045;
  font-weight: 700;
}

.ant-input-affix-wrapper input.ant-input {
  border: unset !important;
}

.ant-modal.register-modal .ant-input-affix-wrapper input.ant-input {
  background: #fff;
  padding: 0 0 0 8px;
}

.ant-modal.register-modal p.footer {
  margin-top: 25px;
  text-align: center;
  font-size: 16px;
  color: #262f39;
}

.ant-modal.register-modal p.footer .ant-btn {
  color: #8fc045;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 700;
  border: unset;
  padding: 0;
  height: unset;
  box-shadow: unset;
  border-bottom: 1px solid #8fc045;
  border-radius: 0;
  margin-left: 5px;
  line-height: 20px;
}

.ant-modal.register-modal small {
  font-size: 14px;
  font-weight: 500;
  margin-right: 4px;
}

.ant-modal .ant-btn.forgot-btn {
  color: #8fc045;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  border: unset;
  box-shadow: unset;
}

.ant-modal .or {
  position: relative;
}

.ant-modal .or hr {
  margin: 30px 0;
}

.ant-modal .or span {
  background-color: #fff;
  color: #626d7d;
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 16px;
}

.ant-modal.register-modal .ant-modal-body .close {
  position: absolute;
  top: 29px;
  right: 35px;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .ant-modal.register-modal {
    width: 90% !important;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .ant-modal.register-modal .ant-modal-body {
    padding: 24px 16px;
  }
  .ant-modal.register-modal .header {
    margin-bottom: 16px;
  }
  .ant-modal.register-modal .header img {
    width: 80px;
    margin-bottom: 16px;
  }
  .ant-modal.register-modal .header h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .ant-modal.register-modal .header p {
    font-size: 11px;
    line-height: 20px;
  }
  .ant-modal.register-modal p.footer {
    margin-top: 16px;
    font-size: 12px;
  }
  .ant-modal.register-modal p.footer .ant-btn {
    font-size: 12px;
    margin-left: 5px;
    line-height: 15px;
  }
  .ant-modal .or hr {
    margin: 30px 0;
  }
  .ant-modal .or span {
    font-size: 10px;
    padding: 5px 16px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ant-modal.register-modal {
    width: 400px !important;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .ant-modal.register-modal {
    width: 450px !important;
  }
  .ant-modal.register-modal .header {
    margin-bottom: 16px;
  }
  .ant-modal.register-modal .header img {
    width: 100px;
    margin-bottom: 16px;
  }
  .ant-modal.register-modal .header h4 {
    font-size: 22px;
    margin-bottom: 16px;
  }
  .ant-modal.register-modal .header p {
    font-size: 13px;
    line-height: 24px;
  }
  .ant-modal.register-modal p.footer {
    margin-top: 16px;
    font-size: 14px;
  }
  .ant-modal.register-modal p.footer .ant-btn {
    font-size: 14px;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .ant-modal .or hr {
    margin: 30px 0;
  }
  .ant-modal .or span {
    font-size: 12px;
    padding: 5px 16px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .ant-modal.register-modal .header img {
    width: 120px;
  }
}
.ant-modal.pdf-modal {
  padding: 0;
  margin: 0;
}

.ant-modal.pdf-modal .ant-modal-body {
  padding: 0;
  margin: 0;
}

.ant-modal.pdf-modal .ant-modal-body .close {
  position: absolute;
  top: unset;
  bottom: 29px;
  right: 35px;
  cursor: pointer;
}

.ant-table {
  overflow: hidden;
}

.ant-table-content {
  overflow-x: auto;
}

.ant-table-content thead tr th {
  background-color: #fff;
  color: #9fa2b4;
  font-size: 14px;
  font-weight: 700;
}

.ant-table-thead > tr > th {
  border-bottom: 1.5px solid #dfe0eb;
  padding: 0 16px 13px 16px;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}

.ant-table-content td {
  color: #262f39;
  font-weight: 500;
  font-size: 14px;
}

.ant-table-tbody > tr > td {
  border-bottom: unset;
}

.ant-table-tbody tr.unread {
  background: rgba(143, 192, 69, 0.0823529412);
}

.ant-table .light {
  color: #737373;
}

.ant-table .view-log {
  font-weight: 500;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.status {
  display: inline-block;
  text-align: center;
  letter-spacing: 0.5px;
  min-width: 125px;
  padding: 0.55rem 8px;
  border-radius: 0.25rem;
  font-weight: 700;
  font-size: 0.6rem;
  color: #ffffff;
  text-transform: uppercase;
}

.status.lg {
  width: 140px;
}

.status.danger {
  background: #d00f24;
}

.status.primary {
  background: #006fad;
}

.status.success {
  background: #348a52;
}

.status.warning {
  background: #ffa800;
}

.status.dark-blue {
  background: #243d8c;
}

.status.dark {
  background: #262f39;
}

.status.wait-sign {
  background: #b46060;
}

.status.signed {
  background: #6527be;
}

.status.signed {
  background: #6527be;
}

.ant-table-content td .image {
  background-position: center;
  background-size: cover;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.ant-table-content td .ant-avatar {
  font-size: 14px !important;
  margin-right: 8px;
  min-width: 30px;
}

.ant-table-content td .upload-info {
  cursor: pointer;
  min-width: 85px;
  display: inline-block;
}

.ant-table-content td .ant-btn.request {
  background: #8fc045;
  color: #ffffff;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.5px;
  border-radius: 6px;
  padding: 8px 20px;
  height: unset;
  border: unset;
}

.ant-table-content td .ant-btn.request:hover {
  background: #77aa2a;
}

.ant-table-content td .ant-btn.edit {
  background: #8fc045;
  color: #ffffff;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.5px;
  border-radius: 6px;
  padding: 8px 9px;
  height: unset;
  border: unset;
}

.ant-table-content td .ant-btn.edit:hover {
  background: #77aa2a;
}

.ant-table-content td .ant-btn.reciept {
  background-color: transparent;
  border: unset;
  box-shadow: unset;
  color: #484848;
  font-weight: 500;
  font-size: 14px;
}

.ant-table-content td .ant-btn.reciept svg {
  margin-left: 23px;
}

.ant-table-content td .ant-btn.reciept span {
  text-decoration: underline;
}

.ant-table-content td .link {
  color: #8fc045;
  text-decoration: underline;
}

.ant-table-content .whatsapp-icon {
  cursor: pointer;
}

.message-svg.active path {
  stroke: #8fc045;
}

.ant-btn.btn-sm {
  width: 36px;
  height: 35px;
  border-radius: 6px;
  padding: unset;
  border: unset;
}

.ant-btn.btn-sm svg {
  height: 20px;
}

.ant-btn.btn-md {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  min-width: 80px;
  border-radius: 6px;
  padding: 8px 9px;
  height: unset;
}

.ant-btn.btn-lg {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  min-width: 107px;
  border-radius: 6px;
  padding: 8px 9px;
  height: unset;
}

.ant-btn.success {
  background: rgba(143, 192, 69, 0.1);
  color: #8fc045;
  border: 1px solid #8fc045;
}

.ant-btn.black {
  background: rgba(38, 47, 57, 0.1);
  color: #262f39;
  border: 1px solid #262f39;
}

.ant-btn.danger {
  background: rgba(255, 47, 72, 0.1);
  color: #ff2f48;
  border: 1px solid #ff2f48;
}

.ant-btn.blue {
  background: #006fad;
  color: #fff;
  border: 1px solid #006fad;
}

.ant-btn.primary {
  background: rgba(0, 111, 173, 0.1);
  color: #006fad;
  border: 1px solid #006fad;
}

.ant-btn.warning {
  background: rgba(255, 168, 0, 0.1);
  color: #ffa800;
  border: 1px solid #ffa800;
}

.ant-btn.wait-sign {
  background: rgba(23, 107, 135, 0.1);
  color: #176b87;
  border: 1px solid #176b87;
}

.ant-btn.signed {
  background: rgba(101, 39, 190, 0.1);
  color: #6527be;
  border: 1px solid #6527be;
}

.ant-btn.to-be-delivered {
  background: rgba(205, 102, 136, 0.1);
  color: #cd6688;
  border: 1px solid #cd6688;
}

.ant-btn.active span {
  display: inline-block;
  position: relative;
}

.ant-btn.active span::before {
  content: "";
  background: #d00f26;
  position: absolute;
  top: -11px;
  left: -19px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.ant-table .ant-picker {
  width: 130px;
  padding: 0;
  background: #ffffff;
  border: 1px solid #cfd3da;
  border-radius: 6px;
}

.ant-table .ant-picker-focused {
  box-shadow: unset;
  border-color: #8fc045;
}

.ant-table .ant-picker .ant-picker-input {
  padding: 6px 8px;
}

.ant-table .ant-picker .ant-picker-input input::-moz-placeholder {
  font-size: 12px;
}

.ant-table .ant-picker .ant-picker-input input,
.ant-table .ant-picker .ant-picker-input input::placeholder {
  font-size: 12px;
}

.ant-table .ant-input {
  width: 130px;
  background: #ffffff;
  border: 1px solid #cfd3da;
  border-radius: 6px;
  font-size: 12px;
  padding: 6px 8px;
}

.ant-table .ant-input::-moz-placeholder {
  font-size: 12px;
}

.ant-table .ant-input::placeholder {
  font-size: 12px;
}

/* Track */
.ant-table-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
.ant-table-content::-webkit-scrollbar-thumb {
  background-color: #222;
  border-radius: 8px;
}

.ant-table-content:hover::-webkit-scrollbar-thumb {
  background-color: #222;
}

.large-table .ant-table-content thead tr th {
  font-size: 12px;
}

.large-table .ant-table-content td {
  font-size: 11px;
}

.large-table .ant-table-tbody > tr > td {
  padding: 12px 12px;
}

@media (max-width: 1199.98px) {
  .ant-table-content thead tr th {
    font-size: 12px;
  }
  .ant-table-content td {
    font-size: 11px;
  }
  .ant-table-tbody > tr > td {
    padding: 12px 12px;
  }
}
@media (max-width: 767.98px) {
  .ant-table-tbody > tr > td {
    padding: 6px 12px;
  }
}
.table-style-2 {
  border: 1px solid #ddd;
  border-radius: 0.75rem;
  overflow: hidden;
}

.table-style-2 .ant-table-content thead tr th {
  background-color: #fff;
  color: #262f39;
  font-size: 0.875rem;
  font-weight: 600;
}

.table-style-2 .ant-table-thead > tr > th {
  border-bottom: 1px solid #ddd;
  padding: 1.25rem 1rem;
}

.table-style-2 .ant-table-content td {
  font-size: 0.875rem;
}

.table-style-2 .ant-table-content tr:not(:last-child) td {
  border-bottom: 1px solid #ddd;
}

.table-style-2 .ant-table-content td .status-2 {
  font-weight: 600;
  padding-left: 0.75rem;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
}

.table-style-2 .ant-table-content td .status-2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.table-style-2 .ant-table-content td .status-2.warning::before {
  background: #ffa800;
}

.table-style-2 .ant-table-content td .status-2.danger::before {
  background: #d93f21;
}

.table-style-2 .ant-table-content td .status-2.purple::before {
  background: #9b51e0;
}

.table-style-2 .ant-table-content td .status-2.hidden::before {
  background: #1f8181;
}

.table-style-2 .ant-table-content td .status-2.blue::before {
  background: #006fad;
}

.table-style-2 .ant-table-content td .status-2.pink::before {
  background: #cd6688;
}

.table-style-2 .ant-table-content td .status-2.success::before {
  background: #8fc045;
}

.table-style-2 .ant-table-content td .status-2.in-process::before {
  background: #262f39;
}

.ant-btn.table-btn {
  font-size: 0.75rem;
  font-weight: 500;
  min-width: 6.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  height: unset;
  border: unset;
  box-shadow: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn.table-btn svg {
  height: 0.913rem;
}

.ant-btn.table-btn.success {
  background: rgba(143, 192, 69, 0.1);
  color: #8fc045;
}

.ant-btn.table-btn.primary {
  background: rgba(0, 111, 173, 0.1);
  color: #006fad;
}

.ant-btn.table-btn.black {
  background: rgba(38, 47, 57, 0.1);
  color: #262f39;
}

.ant-btn.table-btn.view {
  background: rgba(31, 129, 129, 0.1);
  color: #1f8181;
}

.ant-btn.table-btn.danger {
  background: rgba(217, 63, 33, 0.1);
  color: #d93f21;
}

.ant-btn.table-btn.yellow {
  background: rgba(138, 139, 72, 0.1);
  color: #8a8b48;
}

.ant-btn.table-btn.warning {
  background: rgba(255, 168, 0, 0.1);
  color: #ffa800;
}

.ant-btn.table-btn.info {
  background: rgba(23, 162, 184, 0.1);
  color: #17a2b8;
}

.ant-modal.pop-up {
  width: 500px !important;
}

.ant-modal.pop-up .ant-modal-content {
  text-align: center;
}

.ant-modal.pop-up .ant-modal-body {
  padding: 32px;
  position: relative;
}

.ant-modal.pop-up .icon {
  background: #fdeeee;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 24px;
}

.ant-modal.pop-up .icon svg {
  width: 30px;
  height: 30px;
}

.ant-modal.pop-up .approve-icon {
  height: 110px;
  width: 110px;
  margin-bottom: 16px;
}

.ant-modal.pop-up h3 {
  color: #262f39;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 16px;
}

.ant-modal.pop-up p {
  color: #484848;
  line-height: 24px;
  margin-bottom: 40px;
}

.services-dropdown {
  position: relative;
}

.services-dropdown .ant-select {
  background: #ececec;
  color: #222;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.services-dropdown .ant-select-focused {
  border: 1px solid #8fc045;
}

.services-dropdown .ant-select input {
  color: #222;
  font-weight: 600;
}

.services-dropdown .icon {
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
}

.chart {
  box-shadow: 0px 0px 18.418px rgba(0, 0, 0, 0.08);
  border-radius: 9.209px;
  padding: 20px;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.chart-header h3 {
  font-weight: 600;
  font-size: 18px;
}

.demo-toolbar-custom {
  border: 1px solid #d9d9d9;
  border-radius: 5px !important;
}

.rdw-editor-toolbar.demo-toolbar-custom .rdw-option-wrapper {
  border-color: transparent;
}

.demo-editor {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 0px 16px;
  color: #222;
  font-size: 14px;
}

.rdw-link-modal {
  height: unset !important;
}

.ant-dropdown {
  border-radius: 8px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.ant-dropdown-menu {
  padding: 0;
}

.ant-dropdown-menu-item {
  padding: 0;
}

.ant-dropdown-menu-item:hover {
  background: rgba(143, 192, 69, 0.1);
}

.ant-input.input-no-border {
  background-color: transparent;
  border: unset;
  border-radius: 0;
  padding: 0;
  font-size: 0.875rem;
  color: #262f39;
}

.ant-input.input-b-border {
  background-color: transparent;
  border: unset;
  border-bottom: 1px solid #dddddd;
  border-radius: 0;
  padding: 0 0 8px 0;
  font-size: 0.875rem;
  color: #262f39;
}

.ant-input.input-b-border::-moz-placeholder {
  color: #6d6d6d;
  font-weight: 400;
}

.ant-input.input-b-border::placeholder {
  color: #6d6d6d;
  font-weight: 400;
}

.ant-input.input-b-border.sm-input::-moz-placeholder {
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0 0 4px 0;
}

.ant-input.input-b-border.sm-input,
.ant-input.input-b-border.sm-input::placeholder {
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0 0 4px 0;
}

.ant-input.input-b-border.md-input {
  font-weight: 700;
  font-size: 1rem;
}

.ant-input.input-b-border.md-input::-moz-placeholder {
  font-size: 1rem;
}

.ant-input.input-b-border.md-input::placeholder {
  font-size: 1rem;
}

.ant-input.input-b-border.lg-input::-moz-placeholder {
  font-weight: 700;
  font-size: 1.75rem;
}

.ant-input.input-b-border.lg-input,
.ant-input.input-b-border.lg-input::placeholder {
  font-weight: 700;
  font-size: 1.75rem;
}

.ant-input.light-green {
  background: rgba(143, 192, 69, 0.2);
  border-bottom: 1px solid #8fc045;
  padding: 10px 12px;
  border-radius: 0.25rem 0.25rem 0 0;
}

.ant-input.emerald {
  color: #1f8181;
  border-bottom: 1px solid #1f8181;
  font-weight: 500;
  padding: 10px 12px;
  border-radius: 0.25rem 0.25rem 0 0;
}

.ant-input-affix-wrapper.normal-input,
.ant-input.normal-input {
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
  padding: 0.625rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
}

.ant-input.normal-input::-moz-placeholder {
  color: #6d6d6d;
  font-size: 0.75rem;
  font-weight: 400;
}

.ant-input.normal-input::placeholder {
  color: #6d6d6d;
  font-size: 0.75rem;
  font-weight: 400;
}

.ant-input-affix-wrapper.normal-input .ant-input {
  background-color: #fff;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0;
}

.ant-input-affix-wrapper.normal-input .ant-input::-moz-placeholder {
  color: #6d6d6d;
  font-size: 0.75rem;
}

.ant-input-affix-wrapper.normal-input .ant-input::placeholder {
  color: #6d6d6d;
  font-size: 0.75rem;
}

.ant-switch-checked {
  background: #8fc045;
}

.ant-upload {
  width: 100%;
}

.dots-overlay {
  box-shadow: 0px 2px 16px rgba(0, 48, 75, 0.1);
}

.dots-overlay li {
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.688rem 1rem;
  cursor: pointer;
}

.dots-overlay li:hover {
  background: rgba(143, 192, 69, 0.3);
}

.ant-input.light-blue {
  background: rgba(0, 111, 173, 0.2);
  border-bottom: 1px solid #006fad;
  padding: 10px 12px;
  border-radius: 0.25rem 0.25rem 0 0;
}

.form-display {
  width: 100%;
}

@media (max-width: 1199.98px) {
  .form-display {
    width: 100%;
  }
}
.preview-form .input:not(:last-child) {
  margin-bottom: 2.5rem;
}

.preview-form .input > label {
  color: #262f39;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: block;
}

.preview-form .input > label span {
  color: #d93f21;
}

.preview-form .description {
  color: #6d6d6d;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

.preview-form .ant-input {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 0.688rem 0.75rem;
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}

.preview-form .ant-input::-moz-placeholder {
  font-size: 0.875rem;
}

.preview-form .ant-input::placeholder {
  font-size: 0.875rem;
}

.preview-form .ant-input:focus {
  border: 1px solid #8fc045;
}

.preview-form .ant-radio-wrapper span.ant-radio + * {
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}

.preview-form .ant-radio {
  transform: translateY(2px);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #8fc045;
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #8fc045;
}

.preview-form .ant-checkbox-wrapper span:nth-child(2) {
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}

.preview-form .ant-picker {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 0.588rem 0.75rem;
}

.preview-form .ant-picker-focused {
  border: 1px solid #8fc045;
  box-shadow: unset;
}

.preview-form .ant-picker-input > input {
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}

.preview-form .ant-picker-input > input::-moz-placeholder {
  font-size: 0.875rem;
}

.preview-form .ant-picker-input > input::placeholder {
  font-size: 0.875rem;
}

.preview-form .upload-container {
  background: rgba(143, 192, 69, 0.05);
  color: #8fc045;
  font-size: 0.875rem;
  border: 1px dashed #8fc045;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.813rem;
}

.preview-form .upload-container svg {
  width: 1rem;
  margin-right: 0.75rem;
}

.preview-form .upload-container svg path {
  stroke: #8fc045;
}

.preview-form .ant-select {
  padding: 0;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.preview-form .ant-select-selection-placeholder {
  color: #aaaaaa;
  font-size: 0.875rem;
}

.preview-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #262f39;
  font-size: 0.875rem;
  padding: 0.28rem 0.875rem;
  height: 2.8rem;
}

.preview-form .ant-select-item-option-content {
  white-space: normal;
}

@media (max-width: 575.98px) {
  .preview-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0rem 0.875rem;
  }
  .new-form-actions .btn-green-outlined,
  .new-form-actions .btn-green {
    width: 12rem !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}
.ant-select-dropdown.preview-form {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 0;
}

.ant-select-dropdown.preview-form .ant-select-item-option-content {
  color: #262f39;
  font-size: 0.875rem;
  padding: 0.625rem 0.785rem;
  font-weight: 500;
}

.ant-select-dropdown.preview-form .ant-select-item {
  padding: 0;
}

.ant-select-dropdown.preview-form .ant-select-item:not(:last-child) {
  border-bottom: 1px solid rgba(143, 192, 69, 0.3);
}

.ant-select-dropdown.preview-form .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #e3e3e3;
  color: #262f39;
}

.ant-select-dropdown.preview-form .ant-select-item-option-selected,
.ant-select-dropdown.preview-form .ant-select-item-option-active,
.ant-select-dropdown.preview-form .ant-select-item:hover {
  background: #e3e3e3;
  color: #262f39;
}

.single-section:not(:last-child) {
  margin-bottom: 2rem;
}

.single-answer:not(:last-child) {
  margin-bottom: 1.5rem;
}

.ant-image {
  width: 0 !important;
}

.ant-statistic-title {
  color: "#9fa2b4";
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
}

.ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #252733;
  font-weight: 600;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #8fc045;
  border-color: #8fc045;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: transparent;
  border-color: #aaa;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #aaa;
}

.ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #aaa;
  font-weight: 500;
}

.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #8fc045;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #8fc045;
  border-color: #8fc045;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role=button]:hover .ant-steps-item-icon {
  border-color: #8fc045;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role=button]:hover .ant-steps-item-icon svg {
  color: #fff;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role=button]:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role=button]:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role=button]:hover .ant-steps-item-description {
  color: #8fc045;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role=button]:hover .ant-steps-item-icon .ant-steps-icon {
  color: #8fc045;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-wait) > .ant-steps-item-container[role=button]:hover .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
  transform: translateY(2rem);
}

.rtl.App,
.ant-modal-wrap-rtl,
.ant-dropdown,
.ant-select-dropdown-rtl {
  font-family: "Cairo", sans-serif;
}

.rtl .ant-btn.add-new svg {
  margin-left: 16px;
  margin-right: 0;
}

.rtl .nav-language img,
.rtl .nav-language .language {
  margin-right: 0;
  margin-left: 16px;
}

.rtl .header-nav .nav-language .language {
  margin-right: 0;
  margin-left: 11px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .rtl .nav-language {
    margin-right: 0;
    margin-left: 20px;
  }
  .rtl .nav-language .flag,
  .rtl .nav-language .language {
    margin-right: 0;
    margin-left: 10px;
  }
}
.rtl .header-nav h4 {
  margin-right: 0;
  margin-left: 40px;
}

.rtl .header-nav .toggler {
  margin-right: 0;
  margin-left: 20px;
}

.rtl .header-nav .toggler {
  transform: scaleX(-1);
}

.rtl .menu-breadcrumb {
  margin-left: 16px;
  margin-right: 0;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .header-nav h4 {
    margin-left: unset;
  }
}
.rtl .ant-layout-sider .ant-btn.new-request {
  display: flex;
  flex-direction: row-reverse;
}

@media (max-width: 991.98px) {
  .rtl .ant-layout-sider {
    transform: translateX(100%);
  }
  .rtl .ant-layout-sider.nav-open {
    transform: translateX(0%);
  }
}
.rtl .nav-notifications {
  margin: 0 1.25rem 0 2.5rem;
}

.ant-list-rtl .ant-list-item.list-clickable small {
  text-align: left;
}

.ant-input-number-group-rtl .ant-input-number-group-addon {
  border-left: 1px solid rgba(38, 47, 57, 0.2) !important;
  border-right: unset !important;
}

.rtl .profile-form .verification-info h4 {
  text-align: right;
}

.ant-drawer-rtl {
  font-family: "Cairo", sans-serif;
}

.ant-drawer-rtl.know-more .for-whom .details .item svg {
  margin-right: 0 !important;
  margin-left: 24px;
}

.ant-drawer-rtl.know-more .required-papers .item svg {
  margin-right: 0 !important;
  margin-left: 24px;
  transform: rotate(-180deg);
}

@media (max-width: 767.98px) {
  .ant-drawer-rtl.know-more .for-whom .details .item svg {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-drawer-rtl.know-more .required-papers .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
}
.rtl .ant-table-content td .ant-btn.reciept svg {
  margin-right: 23px;
  margin-left: 0;
}

.rtl .ant-table-content td .ant-avatar {
  margin-left: 8px;
  margin-right: 0;
}

.rtl .ant-table-content td .image {
  margin-left: 8px;
  margin-right: 0;
}

.rtl .ant-btn.active span::before {
  top: -11px;
  left: unset;
  right: -33px;
}

.ant-modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.ant-modal-wrap-rtl .ant-modal .ant-modal-body .close {
  left: 35px !important;
  right: unset;
}

.ant-modal-wrap-rtl .ant-modal.register-modal .ant-modal-body .close {
  left: 35px !important;
  right: unset;
}

.ant-modal-wrap-rtl .ant-modal.reciept-modal .ant-modal-body .close {
  left: 35px !important;
  right: unset;
}

.rtl .pagination .ant-btn.prev {
  transform: rotate(-180deg) translateY(-3px);
}

.rtl .pagination .ant-btn.next {
  transform: rotate(0deg);
}

.rtl .services-dropdown .icon {
  right: unset;
  left: 18px;
}

.rtl .services-pg .main-services .item .logo {
  margin-right: 0;
  margin-left: 18px;
}

.rtl .services-pg .services-details .item .details div:first-child {
  margin-right: 0;
  margin-left: 22px;
}

.rtl .services-pg .services-details .item .details svg {
  margin-right: 0;
  margin-left: 7px;
}

.rtl .services-pg .services-details .item .ant-btn.add svg {
  margin-left: 0;
  margin-right: 25px;
  transform: rotate(-180deg);
}

@media (max-width: 1199.98px) {
  .rtl .services-pg .main-services .item .logo {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .services-pg .services-details .item .details div:first-child {
    margin-right: 0;
    margin-left: 16px;
  }
  .rtl .services-pg .services-details .item .details svg {
    margin-right: 0;
    margin-left: 4px;
  }
  .rtl .services-pg .services-details .item .ant-btn.add svg {
    margin-left: 0;
    margin-right: 12px;
  }
}
.rtl .service-pg .intro .inner .details > div:first-child {
  margin-right: 0;
  margin-left: 26px;
}

.rtl .service-pg .intro .inner .details svg {
  margin-right: 0;
  margin-left: 16px;
}

.rtl .service-pg .intro .inner img {
  margin-left: 0;
  margin-right: 46px;
}

.rtl .service-pg .intro .inner .ant-btn {
  margin-left: 20px;
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .rtl .service-pg .intro .inner .details > div:first-child {
    margin-right: 0;
    margin-left: 15px;
  }
  .rtl .service-pg .intro .inner .details svg {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .service-pg .intro .inner img {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .rtl .service-pg .intro .inner img {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .rtl .service-pg .intro .inner .details > div:first-child {
    margin-right: 0;
    margin-left: 26px;
  }
  .rtl .service-pg .intro .inner .details svg {
    margin-right: 0;
    margin-left: 10px;
  }
}
.rtl .service-pg .for-whom .details .item svg {
  margin-right: 0;
  margin-left: 24px;
}

.rtl .service-pg .required-papers .item svg {
  margin-right: 0;
  margin-left: 24px;
  transform: rotate(-180deg);
}

@media (max-width: 575.98px) {
  .rtl .service-pg .for-whom .details .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .service-pg .required-papers .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .rtl .service-pg .for-whom .details .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .service-pg .required-papers .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .rtl .service-pg .for-whom .details .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .service-pg .required-papers .item svg {
    margin-right: 0;
    margin-left: 10px;
  }
}
.profile-pg {
  margin: 60px 30px 0;
}

.profile-pg h2 {
  color: #484848;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 30px;
}

.profile-pg .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}

.profile-pg .buttons .ant-btn {
  border-radius: 8px;
  width: 118px;
  padding: 14px 0;
  font-weight: 600;
  font-size: 16px;
  height: unset;
  border: unset;
  box-shadow: unset;
}

.profile-pg .buttons .ant-btn.cancel {
  color: #9fa1a6;
}

.profile-pg .buttons .ant-btn.cancel.active {
  color: #262f39;
}

.profile-pg .buttons .ant-btn.save {
  background: #8fc045;
  color: #ffffff;
  margin-left: 8px;
}

.profile-pg .buttons .ant-btn.save:hover {
  background: #77aa2a;
}

.rtl .services-pg.services-dashboard .services-details .item .details div:first-child {
  margin-right: 0;
  margin-left: 10px;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .rtl .services-pg.services-dashboard .main-services .item .logo {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .services-pg.services-dashboard .services-details .item .ant-btn.add svg {
    margin-left: 0;
    margin-right: 8px;
  }
}
.rtl .dashboard-pg .item {
  padding: 30px 40px 30px 10px;
}

.rtl .dashboard-pg .item svg {
  margin-left: 40px;
  margin-right: 0;
}

.rtl .dashboard-pg .item p span {
  margin-left: 12px;
  margin-right: 0;
}

.rtl .blog-details .list .item .content svg {
  margin-left: 24px;
  margin-right: 0;
}

.rtl .blog-details .index-info ul {
  margin-right: 30px;
  margin-left: 0;
}

@media (max-width: 991.98px) {
  .rtl .blog-details .list .item .content svg {
    margin-left: 10px;
    margin-right: 0;
  }
  .rtl .blog-details .index-info:not(:last-child) {
    margin-bottom: 20px;
  }
  .rtl .blog-details .index-info ul {
    margin-right: 20px;
    margin-left: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .rtl .blog-details .list .item .content svg {
    margin-left: 14px;
    margin-right: 0;
  }
  .rtl .blog-details .index-info ul {
    margin-right: 24px;
    margin-left: 0;
  }
}/*# sourceMappingURL=styles.css.map */