.rtl .service-pg .intro .inner .details > div:first-child {
  margin-right: 0;
  margin-left: 26px;
}
.rtl .service-pg .intro .inner .details svg {
  margin-right: 0;
  margin-left: 16px;
}
.rtl .service-pg .intro .inner img {
  margin-left: 0;
  margin-right: 46px;
}
.rtl .service-pg .intro .inner .ant-btn {
  margin-left: 20px;
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .rtl .service-pg .intro .inner .details > div:first-child {
    margin-right: 0;
    margin-left: 15px;
  }
  .rtl .service-pg .intro .inner .details svg {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .service-pg .intro .inner img {
    margin-right: 0;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .rtl .service-pg .intro .inner img {
    margin-right: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .rtl .service-pg .intro .inner .details > div:first-child {
    margin-right: 0;
    margin-left: 26px;
  }
  .rtl .service-pg .intro .inner .details svg {
    margin-right: 0;
    margin-left: 10px;
  }
}