.nav-notifications {
  margin: 0 2.5rem 0 1.25rem;
}
.nav-notifications.unseen {
  position: relative;
  cursor: pointer;
}
.nav-notifications.unseen::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #8fc045;
  border: 1.5px solid #f7f8fc;
}

// BELL ICON
.nav-notifications {
  cursor: pointer;
}

// DROPDOWN
.ant-dropdown .nav-notification {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  // padding: 20px 26px;
  width: 320px;
}
.ant-dropdown .nav-notification .nav-notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid #e6ebf1;
}
.ant-dropdown .nav-notification .nav-notification-header h4 {
  color: #252733;
  font-size: 17px;
  font-weight: 600;
}
.ant-dropdown .nav-notification .ant-btn {
  color: #8fc045;
  font-weight: 500;
  border: unset;
  height: unset;
  box-shadow: unset;
}

// BODY
.nav-notification-body {
  padding: 0;
  max-height: 300px;
  overflow: auto;
}

.nav-notification-body .ant-list-sm .ant-list-item {
  padding: 12px 16px;
}

// SCROLL
.nav-notification-body::-webkit-scrollbar {
  width: 2px;
  transition: 0.3s linear;
}
.nav-notification-body:hover::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.nav-notification-body::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
.nav-notification-body::-webkit-scrollbar-thumb {
  background-color: rgba(143, 192, 69, 0.3);
  border-radius: 8px;
}

.nav-notification-body:hover::-webkit-scrollbar-thumb {
  background-color: rgba(143, 192, 69, 0.3);
}

.ant-list-item.list-clickable.unseen {
  background-color: rgba(143, 192, 69, 0.1);
}
.ant-list-item.list-clickable .title {
  color: #252733;
  font-size: 12px;
  font-weight: 700;
}
.ant-list-item.list-clickable .description {
  color: #727272;
  font-size: 12px;
}
.ant-list-item.list-clickable small {
  min-width: 70px;
  margin-left: 8px;
  font-size: 10px;
  text-align: right;
}

// FOOTER
.ant-dropdown .nav-notification .nav-notification-footer {
  padding: 0.65rem;
  text-align: center;
  border-top: 1px solid #e6ebf1;
}

// EMPTY NOTIFICATIONS
.empty-notification {
  padding: 64px 0;
  text-align: center;
}
.empty-notification p {
  color: #727272;
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
}

@media (max-width: 767.98px) {
  .nav-notifications {
    margin-left: 24px;
  }
}
