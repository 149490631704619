.search .ant-input-affix-wrapper {
  background: #E3E3E3;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  padding: 18px;
  color: #262F39;
  font-size: 14px;
  font-weight: 600;
}
.search input::placeholder {
  color: #494949;
  font-size: 14px;
  font-weight: 400;
}

.search .ant-input-affix-wrapper input {
  background-color: #E3E3E3 !important;
  padding-right: 12px;
}
.search .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  box-shadow: unset;
  border: 1px solid #262F39 !important;
}
.search .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid #262F39;
}
.search .ant-input-suffix svg {
  width: 24px;
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .search .ant-input-affix-wrapper {
    border-radius: 5px;
    padding: 12px;
    color: #262F39;
    font-size: 12px;
  }
  .search input::placeholder {
    font-size: 12px;
  }
}