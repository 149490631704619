.ant-layout-sider {
  background: #262f39;
  min-width: 255px !important;
  height: 100vh;
  padding: 34px 0;
  overflow-y: auto;
  position: relative;
}

// LOGO
.ant-layout-sider .logo {
  width: 116px;
  margin: auto;
  margin-bottom: 60px;
}
.ant-layout-sider .logo img {
  width: 100%;
}

// NEW REQUEST
.ant-layout-sider .ant-btn.new-request {
  background: #8fc045;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;
  padding: 16px 0;
  border: unset;
  height: unset;
  width: 219px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 40px;
}
.ant-layout-sider .ant-btn.new-request svg {
  margin-right: 16px;
}
.ant-layout-sider .ant-btn.new-request:hover {
  background: #77aa2a;
}

// MENU CONTENT
.ant-menu {
  background: #262f39;
  padding-bottom: 32px;
}
.ant-menu-submenu-title:active,
.ant-menu-sub.ant-menu-inline {
  background: #262f39;
}
.ant-menu-item-active {
  background: rgba(159, 162, 180, 0.08) !important;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: unset;
}
.menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: unset !important;
  transform: scaleY(0) !important;
}
.ant-menu-inline .ant-menu-item {
  width: 100%;
  margin-top: 0;
}
// SUBMENU
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
  height: 56px !important;
  line-height: 56px !important;
}
// NAV ITEMS
.ant-menu-inline > .ant-menu-item {
  height: 56px;
  line-height: 56px;
}
.ant-menu-title-content a,
.ant-menu-title-content a:hover,
.ant-menu-title-content {
  color: #ffffff;
  font-size: 16px;
}
.ant-menu-inline .ant-menu-submenu svg,
.ant-menu-inline.ant-menu-root .ant-menu-item svg {
  width: 24px;
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 19px;
  color: #fff;
}
.ant-menu-submenu-active .ant-menu-submenu-arrow,
.ant-menu-submenu .ant-menu-submenu-arrow {
  color: #ffffff !important;
}

// DISABLED ITEMS
.ant-menu-item-disabled .ant-menu-title-content {
  color: #828282;
}

// ACTIVE NAV ITEMS
.ant-menu-item-disabled.ant-menu-item:active,
.ant-menu-item.active {
  background: rgba(159, 162, 180, 0.08) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgba(159, 162, 180, 0.08);
}
.ant-menu-item.active .ant-menu-title-content {
  color: #8fc045;
  font-weight: 600;
}
.ant-menu-item.active .anticon,
.ant-menu-submenu-selected .anticon {
  color: #8fc045;
}

// HR
.ant-layout-sider hr {
  border-top: 1px solid #4d4d4d;
  opacity: 1;
  margin-bottom: 0;
  margin-top: 48px;
}

// CLOSE
.ant-layout-sider .close-icon {
  position: absolute;
  top: 27px;
  right: 20px;
  width: 12px;
  cursor: pointer;
  display: none;
}

// LOGOUT
.ant-menu-item.logout {
  margin-top: 30px;
  border-top: 1px solid #4d4d4d;
  height: unset;
  padding: 20px 0;
}

// SCROLL
.ant-layout-sider::-webkit-scrollbar {
  width: 0px;
  transition: 0.3s linear;
}
.ant-layout-sider:hover::-webkit-scrollbar {
  width: 0px;
}
.ant-layout-sider::-webkit-scrollbar-track {
  border-radius: 10px;
}

// COLLAPSED SIDEBAR
.ant-layout-sider.ant-layout-sider-collapsed {
  min-width: unset !important;
}
.ant-layout-sider.ant-layout-sider-collapsed .logo {
  width: 67px;
  margin-bottom: 40px;
}
.ant-layout-sider.ant-layout-sider-collapsed .ant-btn.new-request {
  width: 80%;
}
.ant-layout-sider.ant-layout-sider-collapsed .ant-btn.new-request span {
  display: none;
}
.ant-layout-sider.ant-layout-sider-collapsed .ant-btn.new-request svg {
  margin: 0;
}

.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ant-menu-submenu-popup {
  background-color: transparent;
}
.ant-menu-submenu-popup .ant-menu-title-content {
  color: #262f39;
}

@media (max-width: 991.98px) {
  .ant-layout-sider {
    transform: translateX(-100%);
    position: absolute;
    z-index: 9999;
  }
  .ant-layout-sider.nav-open {
    transform: translateX(0%);
  }
  .ant-layout-sider .close-icon {
    display: block;
  }
}
