@import './date-picker';
@import './select';
@import './search';
@import './table-search';

.table-filters {
  margin: 0 30px 50px;
}
.table-filters label {
  display: block;
  color: #262F39;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 14px;
}

@media (max-width: 1199.98px) {
  .table-filters {
    margin: 0 16px 30px;
  }
  .table-filters label {
    font-size: 12px;
    margin-bottom: 6px;
  }
}