@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@200;300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');

html,
body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App.rtl {
  direction: rtl;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
  color: #262f39;
}
li {
  list-style: none;
  color: #262f39;
}

.ant-layout {
  background: #fff;
}

@media (max-width: 575.98px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  html {
    font-size: 12px;
  }
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  html {
    font-size: 14px !important;
  }
}
@media (min-width: 1500px) {
  html {
    font-size: 16px !important;
  }
}

/* green: '#8FC045',
        green_hover: '#77aa2a',
        green_30: 'rgba(143, 192, 69, 0.03)',
        green_50: 'rgba(143, 192, 69, 0.05)',
        green_80: 'rgba(143, 192, 69, 0.08)',
        green_100: 'rgba(143, 192, 69, 0.1)',
        green_150: 'rgba(143, 192, 69, 0.15)',
        green_200: 'rgba(143, 192, 69, 0.2)',
        green_300: 'rgba(143, 192, 69, 0.3)',
        blue: '#006fad',
        blue_150: 'rgba(0, 111, 173, 0.15)',
        blue_100: 'rgba(0, 111, 173, 0.1)',
        blue_300: 'rgba(0, 111, 173, 0.3)',
        black: '#262F39',
        black_100: 'rgba(38, 47, 57, 0.1)',
        black_150: 'rgba(38, 47, 57, 0.15)',
        gray: '#484848',
        gray_100: '#DEE2E6',
        gray_200: '#CFD3DA',
        gray_300: '#626D7D',
        gray_400: '#868686',
        gray_500: '#727272',
        gray_600: '#D9D9D9',
        gray_700: '#5C5C5C',
        gray_800: 'rgba(102, 102, 102, 0.8)',
        dimmed: '#6D6D6D',
        danger_50: '#dc35451f',
        danger: '#D93F21',
        danger_150: 'rgba(217, 63, 33, 0.15)',
        warning: '#ffa800',
        warning_150: 'rgba(255, 168, 0, 0.15)',
        primary: '#006fad',
        dark_blue: '#243d8c',
        success: '#348a52',
        orange: '#F2994A',
        hidden: '#1F8181',
        hidden_150: 'rgba(31, 129, 129, 0.15)',
        purple: '#9B51E0',
        purple_150: 'rgba(155, 81, 224, 0.15)',
        pink: '#CD6688', */

/* BACKGROUNDS */
.bg-white {
  background: #fff;
}
.bg-green-30 {
  background: rgba(143, 192, 69, 0.03);
}
.bg-green-50 {
  background: rgba(143, 192, 69, 0.05);
}
.bg-green-100 {
  background: rgba(143, 192, 69, 0.1);
}
.bg-green-200 {
  background: rgba(143, 192, 69, 0.2);
}
.bg-warning-200 {
  background: rgba(255, 168, 0, 0.2);
}
.bg-danger-200 {
  background: rgba(217, 63, 33, 0.2);
}
.bg-green {
  background: #8fc045;
}
.bg-blue-50 {
  background: rgba(0, 111, 173, 0.05);
}
.bg-blue-100 {
  background: rgba(0, 111, 173, 0.1);
}
.bg-blue-200 {
  background: rgba(0, 111, 173, 0.2);
}
.bg-blue {
  background: #006fad;
}
.bg-black {
  background: #262f39 !important;
}
.bg-black-100 {
  background: rgba(38, 47, 57, 0.1) !important;
}
.bg-gray-100 {
  background-color: #fdfdfd;
}
.bg-gray-200 {
  background-color: #ededed;
}
.bg-gray-400 {
  background: #f2f2f2 !important;
}
.bg-gray-500 {
  background: #e3e3e3;
}
.bg-gray-600 {
  background: #f5f5f5;
}
.bg-gray-30 {
  background: rgba(38, 47, 57, 0.03);
}

/* TEXT-COLOR */
.text-white {
  color: #fff;
}
.text-transparent {
  color: transparent;
}
.text-dimmed {
  color: #6d6d6d;
}
.text-label {
  color: #6d6d6d;
}
.text-danger {
  color: #d93f21;
}
.text-black {
  color: #262f39;
}
.text-green {
  color: #8fc045 !important;
}
.text-blue {
  color: #006fad;
}
.text-purple {
  color: #5d5fef;
}
.text-hidden {
  color: #1F8181
}
.text-blue-400 {
  color: #77808a;
}
.text-gray-300 {
  color: #aaa;
}
.text-gray-600 {
  color: #6d6d6d;
}
.text-purple {
  color: #5d5fef;
}
.text-green-2 {
  color: #1f8181;
}

/* FONT SIZES */
.fs-10 {
  font-size: 0.625rem;
}
.fs-11 {
  font-size: 0.688rem;
}
.fs-12 {
  font-size: 0.75rem;
}
.fs-13 {
  font-size: 0.813rem;
}
.fs-14 {
  font-size: 0.875rem;
}
.fs-18 {
  font-size: 1.125rem;
}
.fs-20 {
  font-size: 1.25rem !important;
}
.fs-22 {
  font-size: 1.375rem;
}
.fs-24 {
  font-size: 1.5rem;
}
.fs-28 {
  font-size: 1.75rem;
}
.fs-64 {
  font-size: 4rem;
}

/* FONT WEIGHT */
.fw-600 {
  font-weight: 600;
}

/* TEXT OVERFLOW */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* PADDING */
.dashboard-container {
  padding: 2rem 2rem;
}

.p-12 {
  padding: 0.75rem;
}
.p-14 {
  padding: 0.875rem;
}
.p-18 {
  padding: 1.125rem;
}
.p-24 {
  padding: 1.5rem;
}
.p-32 {
  padding: 2rem;
}
.p-48 {
  padding: 3rem;
}
.p-72 {
  padding: 4.5rem;
}

.px-6 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.px-12 {
  padding: 0 0.75rem;
}
.px-20 {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
.px-24 {
  padding: 0 1.5rem;
}
.px-32 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-40 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-50 {
  padding: 0 3.125rem;
}

.py-12 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-14 {
  padding: 0.875rem 0;
}
.py-18 {
  padding: 1.125rem 0;
}
.py-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-32 {
  padding: 2rem 0;
}
.py-48 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-56 {
  padding: 3.5rem 0;
}

.pt-12 {
  padding-top: 0.75rem;
}
.pt-32 {
  padding-top: 2rem;
}
.pt-40 {
  padding-top: 2.5rem;
}

.pb-12 {
  padding-bottom: 0.75rem;
}
.pb-80 {
  padding-bottom: 5rem;
}

/* MARGINS */
.mb-6 {
  margin-bottom: 0.375rem;
}
.mb-10 {
  margin-bottom: 0.625rem;
}
.mb-12 {
  margin-bottom: 0.75rem;
}
.mb-14 {
  margin-bottom: 0.875rem;
}
.mb-20 {
  margin-bottom: 1.25rem;
}
.mb-30 {
  margin-bottom: 1.875rem;
}
.mb-32 {
  margin-bottom: 2rem;
}
.mb-40 {
  margin-bottom: 2.5rem;
}
.mb-48 {
  margin-bottom: 3rem;
}
.mb-56 {
  margin-bottom: 3.5rem;
}
.mb-64 {
  margin-bottom: 4rem;
}
.mb-80 {
  margin-bottom: 5rem;
}

.mx-12 {
  margin: 0 0.75rem;
}
.my-12 {
  margin: 0.75rem 0;
}
.my-40 {
  margin: 2.5rem 0;
}

.mt-12 {
  margin-top: 0.75rem;
}
.mt-20 {
  margin-top: 1.25rem;
}
.mt-40 {
  margin-top: 2.5rem;
}
.mt-48 {
  margin-top: 3rem;
}
.mt-56 {
  margin-top: 3.5rem;
}

/* GAP */
.gap-12 {
  gap: 0.75rem;
}

/* BORDERS */
.border-0 {
  border: none;
}
.border-black {
  border: 1px solid #262f39 !important;
}
.border-blue-500 {
  border: 1px solid #c7c9df;
}
.border-transparent {
  border: 1px solid transparent;
}
.border-green {
  border: 1px solid #8fc045;
}
.border-t-danger-4 {
  border-top: 0.25rem solid #D93F21;
}
.border-t-green-8 {
  border-top: 0.5rem solid #8fc045;
}
.border-t-green-16 {
  border-top: 1rem solid #8fc045;
}
.border-t-blue-16 {
  border-top: 1rem solid #006fad;
}
.dashed-gray-border {
  border: 1px dashed #aaaaaa;
}
.dashed-green-border {
  border: 1px dashed #8fc045;
}
.border-green-80 {
  border: 1px solid rgba(143, 192, 69, 0.08);
}
.border-green-200 {
  border: 1px solid rgba(143, 192, 69, 0.2);
}
.border-green-400 {
  border: 1px solid rgba(143, 192, 69, 0.4);
}
.border-blue-300 {
  border: 1px solid rgba(0, 111, 173, 0.3);
}
.border-blue {
  border: 1px solid #006fad;
}

/* BORDER COLOR */
.border-light {
  border-color: #aaa;
}
.border-dimmed {
  border-color: #6d6d6d;
}
.border-gray-500 {
  border-color: #dddddd;
}

/* BORDER RADIUS */
.radius-4 {
  border-radius: 0.25rem;
}
.radius-8 {
  border-radius: 0.5rem;
}
.radius-12 {
  border-radius: 0.75rem;
}
.radius-16 {
  border-radius: 1rem;
}

/* BOX SHADOW */
.shadow-card {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
}
.shadow-md {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* OPACITY */
.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 1;
}

/* BACKGROUNG IMAGE */
.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* TRANSFORM */
.rotate-180 {
  transform: rotate(-180deg);
}

/* TRANSITION */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* VISIBILITY */
.visibility-hidden {
  visibility: hidden;
}

/* WIDTH */
.min-w-16 {
  min-width: 1rem;
}
.min-w-48 {
  min-width: 3rem;
}
.width-16 {
  width: 1rem;
}
.w-12 {
  width: 0.75rem;
}
.w-16 {
  width: 1rem;
}
.w-24 {
  width: 1.5rem;
}
.w-32 {
  width: 2rem;
}
.w-40 {
  width: 2.5rem;
}

/* HEIGHT */
.h-auto {
  height: auto;
}
.h-16 {
  height: 1rem;
}
.h-24 {
  height: 1.5rem;
}
.h-32 {
  height: 2rem;
}
.h-40 {
  height: 2.5rem !important;
}
.h-48 {
  height: 3rem;
}

/* FLEX */
.flex-1 {
  flex: 1;
}

/* OVERFLOW */
.overflow-hidden {
  overflow: hidden;
}

/* BUTTONS */
.ant-btn.btn-green {
  background: #8fc045;
  border-color: #8fc045;
  color: #fff;
  letter-spacing: 0.5px;
  height: unset;
  box-shadow: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-btn.btn-green:hover {
  background: #86c527;
  border-color: #86c527;
}
.ant-btn[disabled].btn-green {
  background: #d3d3d3;
  border-color: #d3d3d3;
  color: #fff;
}

.ant-btn.btn-black {
  background: #262f39;
  border-color: #262f39;
  color: #fff;
  letter-spacing: 0.5px;
  height: unset;
  box-shadow: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-btn.btn-black:hover {
  background: #262f39;
  border-color: #262f39;
}
.ant-btn[disabled].btn-black {
  background: #d3d3d3;
  border-color: #d3d3d3;
  color: #fff;
}

.ant-btn.btn-green-outlined {
  background: #fff;
  border-color: #8fc045;
  color: #8fc045;
  letter-spacing: 0.5px;
  height: unset;
  box-shadow: unset;
}
.ant-btn.btn-green-outlined:hover {
  background: #8fc045;
  color: #fff;
  border-color: #8fc045;
}

.ant-btn.btn-blue-outlined {
  background: #fff;
  border-color: #006fad;
  color: #006fad;
  letter-spacing: 0.5px;
  height: unset;
  box-shadow: unset;
}
.ant-btn.btn-blue-outlined:hover {
  background: #006fad;
  color: #fff;
  border-color: #006fad;
}

.ant-btn.text-green:hover {
  background: #fff;
  border-color: transparent;
}

.ant-btn.btn-text-blue {
  background: transparent;
  color: #006fad;
  height: unset;
  box-shadow: unset;
  padding: unset;
  letter-spacing: 0.5px;
  border: unset;
}
.ant-btn.btn-text-blue svg {
  margin-right: 0.5rem;
}

.ant-btn.black-outlined {
  background-color: #fff;
  color: #262f39;
  font-weight: 500;
  font-size: 0.875rem;
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
  padding: 0.75rem 0.875rem;
  height: unset;
  box-shadow: unset;
  text-align: left;
  display: flex;
  align-items: center;
}
.ant-btn.black-outlined svg {
  width: 1rem;
  margin-right: 0.5rem;
}

.ant-btn.btn-transparent {
  background-color: transparent;
  height: unset;
  border: unset;
  padding: unset;
  box-shadow: unset;
}

.ant-btn.btn-transparent.blue {
  color: #1f8181;
}
.ant-btn.btn-transparent.blue svg path,
.ant-btn.btn-transparent.blue svg circle {
  stroke: #1f8181;
}

.ant-btn.dashed-outlined {
  background-color: transparent;
  color: #8fc045;
  border: 1px dashed #8fc045;
  border-radius: 0.25rem;
  padding: 0.75rem 1.625rem;
  height: unset;
}

.ant-btn.dashed-outlined-blue {
  background-color: transparent;
  color: #006fad;
  border: 1px dashed #006fad;
  border-radius: 0.25rem;
  padding: 0.75rem 1.625rem;
  height: unset;
}

/* SVGS */
.svg-black path {
  stroke: #262f39;
}
.svg-green path {
  stroke: #8fc045;
}

/* OTHERS */
.cursor-pointer {
  cursor: pointer;
}

/* POP CONFIRM */
.ant-popover-message-title {
  font-size: 1rem;
  font-weight: 500;
  color: #262f39;
}
.ant-popover-buttons .ant-btn.ant-btn-primary {
  background: #d93f21;
  border-color: #d93f21;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  height: unset;
  box-shadow: unset;
}
.ant-popover-buttons .ant-btn.ant-btn-primary.warning {
  background: #ffa800;
  border-color: #ffa800;
}
.ant-popover-buttons .ant-btn.ant-btn-primary.darkYellow {
  background: #ada942;
  border-color: #ada942;
}
.ant-popover-buttons .ant-btn.ant-btn-primary.danger {
  background: #d93f21;
  border-color: #d93f21;
}
.ant-popover-buttons .ant-btn.ant-btn-primary.purple {
  background: #9b51e0;
  border-color: #9b51e0;
}
.ant-popover-buttons .ant-btn.ant-btn-primary.green {
  background: #8fc045;
  border-color: #8fc045;
}
.ant-popover-buttons .ant-btn.ant-btn-primary.hidden {
  background: #1f8181;
  border-color: #1f8181;
}
.ant-popover-buttons .ant-btn.ant-btn-primary.black {
  background: #262f39;
  border-color: #262f39;
}
.ant-popover-buttons .ant-btn.ant-btn-primary.blue {
  background: #006fad;
  border-color: #006fad;
}

.ant-popover-buttons .ant-btn.ant-btn-default {
  background: transparent;
  color: #262f39;
  font-size: 0.875rem;
  font-weight: 600;
  border: unset;
}

/* NEW STATUS */
.status-new {
  border-radius: 0.5rem;
  padding: 0.5rem 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;
}
.status-new.warning {
  background-color: rgba(255, 168, 0, 0.15);
}
.status-new.darkYellow {
  background-color: rgba(173, 169, 66, 0.15);
}
.status-new.danger {
  background-color: rgba(217, 63, 33, 0.15);
}
.status-new.purple {
  background-color: rgba(155, 81, 224, 0.15);
}
.status-new.green {
  background-color: rgba(143, 192, 69, 0.15);
}
.status-new.hidden {
  background-color: rgba(31, 129, 129, 0.15);
}
.status-new.black {
  background-color: rgba(38, 47, 57, 0.15);
}
.status-new.blue {
  background-color: rgba(0, 111, 173, 0.15);
}

.status-new > span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}
.status-new.warning > span {
  background-color: #ffa800;
}
.status-new.darkYellow > span {
  background-color: #ada942;
}
.status-new.danger > span {
  background-color: #d93f21;
}
.status-new.purple > span {
  background-color: #9b51e0;
}
.status-new.green > span {
  background-color: #8fc045;
}
.status-new.hidden > span {
  background-color: #1f8181;
}
.status-new.black > span {
  background-color: #262f39;
}
.status-new.blue > span {
  background-color: #006fad;
}

.status-new p {
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
}

.status-details {
  color: #006fad;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
}
.status-details.with-bg {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.status-details.with-bg.warning {
  background-color: rgba(255, 168, 0, 0.05);
}
.status-details.with-bg.darkYellow {
  background-color: rgba(173, 169, 66, 0.05);
}
.status-details.with-bg.danger {
  background-color: rgba(217, 63, 33, 0.05);
}
.status-details.with-bg.purple {
  background-color: rgba(155, 81, 224, 0.05);
}
.status-details.with-bg.green {
  background-color: rgba(143, 192, 69, 0.05);
}
.status-details.with-bg.hidden {
  background-color: rgba(31, 129, 129, 0.05);
}
.status-details.with-bg.black {
  background-color: rgba(38, 47, 57, 0.05);
}
.status-details.with-bg.blue {
  background-color: rgba(0, 111, 173, 0.05);
}
.status-details.warning {
  color: #ffa800;
}
.status-details.darkYellow {
  color: #ada942;
}
.status-details.danger {
  color: #d93f21;
}
.status-details.purple {
  color: #9b51e0;
}
.status-details.green {
  color: #8fc045;
}
.status-details.hidden {
  color: #1f8181;
}
.status-details.black {
  color: #262f39;
}
.status-details.blue {
  color: #006fad;
}

.status-details span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  position: relative;
}
.status-details.warning span {
  background-color: rgba(255, 168, 0, 0.15);
}
.status-details.darkYellow span {
  background-color: rgba(173, 169, 66, 0.15);
}
.status-details.danger span {
  background-color: rgba(217, 63, 33, 0.15);
}
.status-details.purple span {
  background-color: rgba(155, 81, 224, 0.15);
}
.status-details.green span {
  background-color: rgba(143, 192, 69, 0.15);
}
.status-details.hidden span {
  background-color: rgba(31, 129, 129, 0.15);
}
.status-details.black span {
  background-color: rgba(38, 47, 57, 0.15);
}
.status-details.blue span {
  background-color: rgba(0, 111, 173, 0.15);
}
.status-details span::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 9px;
  height: 9px;
  border-radius: 50%;
}
.status-details.warning span::after {
  background-color: #ffa800;
}
.status-details.darkYellow span::after {
  background-color: #ada942;
}
.status-details.danger span::after {
  background-color: #d93f21;
}
.status-details.purple span::after {
  background-color: #9b51e0;
}
.status-details.green span::after {
  background-color: #8fc045;
}
.status-details.hidden span::after {
  background-color: #1f8181;
}
.status-details.black span::after {
  background-color: #262f39;
}
.status-details.blue span::after {
  background-color: #006fad;
}

/* ======================= OTP INPUT ======================== */
.otp-input input {
  /* @apply border border-gray_200 rounded-md text-sm font-semibold h-50 !w-50; */
  border: 1px solid #cfd3da;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 600;
  width: 3.125rem !important;
  height: 3.125rem;
}

/* ======================= HTML TO REACT RENDER ======================== */
.html-to-react {
  font-size: 1rem;
}
.html-to-react ul li {
  list-style-type: disc;
  list-style-position: inside;
}

/* ======================= TABS ======================== */
.ant-tabs-nav {
  margin-bottom: 0 !important;
}
.ant-tabs-tab-btn {
  color: #6D6D6D;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #262F39;
  font-weight: 600;
}
.ant-tabs-tab:hover {
  color: #8fc045;
}
.ant-tabs-ink-bar {
  background: #8fc045;
}
.ant-tabs-nav-wrap {
  padding: 0 1.5rem;
}