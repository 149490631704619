.rtl .header-nav h4 {
  margin-right: 0;
  margin-left: 40px;
}
.rtl .header-nav .toggler {
  margin-right: 0;
  margin-left: 20px;
}

// NAV TOGLER
.rtl .header-nav .toggler {
  transform: scaleX(-1);
}

.rtl .menu-breadcrumb {
  margin-left: 16px;
  margin-right: 0;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .header-nav h4 {
    margin-left: unset;
  }
}
