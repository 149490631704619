.rtl .ant-layout-sider .ant-btn.new-request {
  display: flex;
  flex-direction: row-reverse;
}

@media (max-width: 991.98px) {
  .rtl .ant-layout-sider {
    transform: translateX(100%);
  }
  .rtl .ant-layout-sider.nav-open {
    transform: translateX(0%);
  }
}