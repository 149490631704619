.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #252733;
  font-weight: 600;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #8fc045;
  border-color: #8fc045;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: transparent;
  border-color: #aaa;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #aaa;
}
.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #aaa;
  font-weight: 500;
}
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #8fc045;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #8fc045;
  border-color: #8fc045;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon {
  border-color: #8fc045;
}
// .ant-steps
//   .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
//   > .ant-steps-item-container[role='button']
//   .ant-steps-item-icon
//   svg {
//     transform: translateY(-2px);
// }
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon
  svg {
  color: #fff;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-subtitle,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-description {
  color: #8fc045;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: #8fc045;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-wait)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: #fff;
  transform: translateY(2rem);
}
