#main-form-style {
  .image-upload {
    display: block;
    width: 8rem;
    height: 8rem;
    margin: 0 auto 1.5rem;

    .card {
      background-color: rgba(143, 192, 69, 0.05);
      border: 1px solid rgba(143, 192, 69, 0.3);
      border-radius: 0.75rem;
      text-align: center;
      width: 8rem;
      height: 8rem;

      .image {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0.75rem;
        position: relative;

        .icon {
          width: 2.5rem;
          height: 2.5rem;
          background-color: #fff;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 50%);
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
        }
      }
    }

    h5 {
      color: #8fc045;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    p {
      color: #6d6d6d;
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }
}
