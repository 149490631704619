// HEADER WITH [TITLE - CLOSE]
.ant-modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

// CHATBOX
// .ant-modal .chatbox {
//   background: #f5f5f5;
//   border: 1px solid #f5f5f5;
//   border-radius: 4px;
//   max-height: 224px;
//   overflow-y: auto;
//   padding: 16px;
//   margin-bottom: 16px;
// }
// .ant-modal .chatbox .single-chat .header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 4px;
// }
// .ant-modal .chatbox .single-chat .header h6 {
//   color: #262f39;
//   font-weight: 600;
//   font-size: 12px;
// }
// .ant-modal .chatbox .single-chat .header p {
//   color: #5c5c5c;
//   font-size: 12px;
// }
// .ant-modal .chatbox .single-chat .desc {
//   font-weight: 300;
//   font-size: 14px;
// }

// CLOSE ICON
.ant-modal-wrap-rtl .ant-modal .ant-modal-body .close {
  left: 35px !important;
  right: unset;
}

@import './register';
@import './transfer-details';
@import './new-request';