#main-form-style {
  .radio-group {
    .radio-group-label {
      font-size: 0.875rem;
      color: #6D6D6D;
      margin-bottom: 0.5rem;
    }

    .radio-group-input {
      border: 1px solid #DDD;
      border-radius: 0.5rem; 
      padding: 1rem 1.25rem;
    }

    .radio-option {
      font-size: 1rem;
      color: #262F39;
      font-weight: 600;
    }
  }
}