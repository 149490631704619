.rtl .ant-table-content td .ant-btn.reciept svg {
  margin-right: 23px;
  margin-left: 0;
}

.rtl .ant-table-content td .ant-avatar {
  margin-left: 8px;
  margin-right: 0;
}
.rtl .ant-table-content td .image {
  margin-left: 8px;
  margin-right: 0;
}

.rtl .ant-btn.active span::before {
  top: -11px;
  left: unset;
  right: -33px;
}
