.services-dropdown {
  position: relative;
}
.services-dropdown .ant-select {
  background: #ececec;
  color: #222;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.services-dropdown .ant-select-focused {
  border: 1px solid #8fc045;
}
.services-dropdown .ant-select input {
  color: #222;
  font-weight: 600;
}
.services-dropdown .icon {
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
}