.blog-pg {
  padding: 40px 0 80px;
}
.blog-pg h3 {
  color: #262f39;
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 40px;
}

// ITEM
.blog-pg .item {
  background: #ffffff;
  border: 0.81862px solid #d9d9d9;
  border-radius: 4.0931px;
  overflow: hidden;
  cursor: pointer;
}
.blog-pg .item .image {
  height: 173px;
  background-size: cover;
  background-position: center;
}
.blog-pg .body {
  padding: 14px 20px 30px;
  height: 209px;
  overflow: hidden;
}
.blog-pg .body .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}
.blog-pg .body .title h6 {
  color: #262f39;
}
.blog-pg .body .title .date {
  color: #8fc045;
}

.blog-pg .body h4.question {
  color: #8fc045;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}
.blog-pg .body p.answer {
  color: #727272;
  font-size: 14px;
  line-height: 26px;
}

// ACTIONS
.blog-pg .item .actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1199.98px) {
  .blog-pg {
    padding: 30px 0;
  }
  .blog-pg h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  // ITEM
  .blog-pg .item .image {
    height: 173px;
  }
  .blog-pg .body {
    padding: 14px 16px 14px;
    height: unset;
    overflow: hidden;
  }
  .blog-pg .body .title {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .blog-pg .body h4.question {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .blog-pg .body p.answer {
    font-size: 12px;
    line-height: 26px;
  }

  // ACTIONS
  .blog-pg .item .actions {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .blog-pg {
    padding: 40px 0;
  }
  .blog-pg .item .image {
    height: 260px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .blog-pg .item .image {
    height: 173px;
  }
  .blog-pg .body {
    height: 160px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .blog-pg {
    padding: 40px 0 60px 0;
  }
  .blog-pg .body h4.question {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .blog-pg .body {
    height: 180px;
  }
}