.icon-upload {
  display: block;
  width: 200px;
  margin: auto;
  margin-bottom: 16px;
  text-align: center;
}
.icon-upload .upload {
  border: 1px dashed #8fc045;
  color: #8fc045 !important;
}
.icon-upload .upload .content svg path {
  fill: #8fc045;
}
