#main-form-style {
  .upload-input-content {
    background-color: rgba(143, 192, 69, 0.1);
    color: #8fc045;
    font-size: 0.875rem;
    border: 1px dashed #8fc045;
    border-radius: 0.5rem; 
    padding: 1.08rem 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .ant-upload-list-text-container {
    display: none;
  }
}
