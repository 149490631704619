.faqs {
  padding: 3rem;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.06);

  .faq {
    .question {
      padding: .25rem 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 1rem;
      cursor: pointer;

      h6 {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.75rem;
      }
    }

    .answer {
      max-height: 0;
      overflow: hidden;

      &.open {
        padding-top: 0.75rem;
        padding-top: 1.25rem;
        max-height: 1000px;
      }

      p {
        font-size: 0.875rem;
        line-height: 1.75rem;
      }
    }
  }
}
