.ant-picker-dropdown {
  z-index: 99992;
}

.profile-form .ant-picker {
  background: #F5F5F5;
  border-radius: 5px;
  border: unset;
  width: 100%;
  padding: 18px 16px;
}
.profile-form .ant-picker-input input::placeholder {
  color: #656565;
  font-weight: 500;
  font-size: 14px;
}
.profile-form .ant-picker-input input {
  color: #262f39;
  font-weight: 600 !important;
}
.profile-form .ant-picker-focused {
  border-color: unset;
  box-shadow: unset;
  outline: 0;
}
.profile-form .ant-picker:hover,
.profile-form .ant-picker-focused {
  border-color: unset;
}

.ant-picker-clear {
  background: transparent;
  width: 44px;
}

@media (max-width: 1199.98px) {
  .profile-form .ant-picker {
    border-radius: 5px;
    padding: 12px 16px;
  }
  .profile-form .ant-picker-input input {
    font-size: 12px;
  }
  .profile-form .ant-picker-input input::placeholder {
    font-size: 12px;
  }
  .profile-form .ant-picker-suffix svg {
    width: 20px;
  }
}
