// MODAL WIDTH
.ant-modal.modal-xs {
  width: 400px !important;
}
.ant-modal.modal-sm {
  width: 500px !important;
}
.ant-modal.modal-md {
  width: 600px !important;
}
.ant-modal.modal-lg {
  width: 800px !important;
}
.ant-modal.modal-xxl {
  width: 1000px !important;
}

// HEADER
.ant-modal h3 {
  color: #484848;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;
}

// HEADER WITH [TITLE - CLOSE]
.ant-modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.ant-modal .header h3 {
  margin-bottom: 0;
}
.ant-modal .header .close {
  cursor: pointer;
}

// UPLOAD
.ant-modal .ant-upload-list.ant-upload-list-text {
  display: block;
}
.ant-modal .ant-upload-list .ant-tooltip {
  display: none;
}

// USERNAME
.ant-modal .user-name {
  text-align: center;
  margin-bottom: 24px;
}
.ant-modal .user-name .image {
  background-size: contain;
  background-position: center;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 16px;
}
.ant-modal .user-name .name {
  color: #484848;
  font-weight: 600;
  font-size: 20px;
}

// SMALL HEADER W DESCRIPTION
.ant-modal h5 {
  color: #8fc045;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}
.ant-modal p.desc {
  color: #262f39;
  font-weight: 600;
}
.ant-modal a.desc {
  display: block;
  font-weight: 600;
  text-decoration: underline;
  line-height: 28px;
}

// INPUT
.ant-modal .form .ant-input {
  padding: 12px 16px;
}
.ant-modal .form .ant-input::placeholder {
  color: #656565;
}

// BUTTONS [SUBMIT - CANCEL]
.ant-modal .buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-modal .buttons .ant-btn {
  color: #484848;
  font-weight: 600;
  border-radius: 8px;
  border: unset;
  height: unset;
  box-shadow: unset;
}
.ant-modal .buttons .ant-btn.submit {
  color: #ffffff !important;
  padding: 10px 30px;
}
.ant-modal .buttons .ant-btn.success {
  background: #8fc045;
}
.ant-modal .buttons .ant-btn.success:hover {
  background: #77aa2a;
}
.ant-modal .buttons .ant-btn.danger {
  background: #fa4458;
}
.ant-modal .buttons .ant-btn.danger:hover {
  background: #ff2f48;
}
.ant-modal .buttons .ant-btn.black {
  background: #262f39;
}
.ant-modal .buttons .ant-btn.black:hover {
  background: #262f39;
}
.ant-modal .buttons .ant-btn.wait-sign {
  background: #176b87;
}
.ant-modal .buttons .ant-btn.wait-sign:hover {
  background: #176b87;
}

// CHATBOX
.ant-modal .chatbox {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  height: 100%;
  max-height: 333px;
  overflow-y: auto;
  padding: 16px;
  margin-bottom: 16px;
}
.ant-modal .chatbox .single-chat .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.ant-modal .chatbox .single-chat:not(:last-child) {
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 16px;
  margin-bottom: 8px;
}
.ant-modal .chatbox .single-chat .header h6 {
  color: #262f39;
  font-weight: 600;
  font-size: 12px;
}
.ant-modal .chatbox .single-chat .header p {
  color: #5c5c5c;
  font-size: 12px;
}
.ant-modal .chatbox .single-chat .header p span {
  color: #8fc045;
  font-size: 10px;
}
.ant-modal .chatbox .single-chat h6 {
  color: #262f39;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 6px;
}
.ant-modal .chatbox .single-chat .desc {
  font-weight: 300;
  font-size: 11px;
}
.ant-modal .chatbox .single-chat a {
  color: #8fc045;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
}

// SCROLL
.ant-modal .chatbox::-webkit-scrollbar {
  width: 6px;
  transition: 0.3s linear;
}
.ant-modal .chatbox:hover::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.ant-modal .chatbox::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
.ant-modal .chatbox::-webkit-scrollbar-thumb {
  background-color: #828282;
  border-radius: 8px;
}

// CLOSE ICON
.ant-modal .ant-modal-body .close {
  position: absolute;
  top: 29px;
  right: 35px;
  cursor: pointer;
}

@import './register';
@import './pdf';
