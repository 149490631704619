#main-form-style {
  .search-input {
    label {
      font-size: 0.875rem;
      color: #6d6d6d;
      margin-bottom: 0.5rem;
    }

    .ant-input-affix-wrapper {
      width: 100% !important;
      background: rgba(221, 221, 221, 0.2);
      border: 1px solid #ddd;
      border-radius: 0.5rem;
      padding: 1rem 0.875rem;
      font-size: 1rem;
      font-weight: 600;
    }
    input::placeholder {
      font-size: 1rem;
      color: #aaa;
      font-weight: 300;
    }
    .ant-input-affix-wrapper input.ant-input {
      background-color: transparent;
      padding-left: 0; // REMOVE IT IN THE FUTURE
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      box-shadow: unset;
      border: 1px solid #8fc045 !important;
    }
    .search
      .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border: 1px solid #8fc045;
    }

    // HANDLE SM SIZE
    &.sm {
      .ant-input-affix-wrapper {
        border: 1px solid rgba(221, 221, 221, 0.2);
        padding: 0.75rem 0.875rem;
      }
    }
  }
}
