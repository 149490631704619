@import './form/form';
@import './modals/modals';
@import './table';
@import './table-style-2';
@import './popups/popup';
@import './dropdown/dropdown';
@import './draft-editor';
@import './general-dropdown';

@import './buildingForms/building-forms';

.ant-image {
  width: 0 !important;
}

.ant-statistic-title {
  color: '#9fa2b4';
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
}