.ant-layout-header {
  height: unset;
  line-height: unset;
  background: #fff;
  padding: 32px 30px 23px;
}

// TITLE
.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-nav .left,
.header-nav .right {
  display: flex;
  align-items: center;
}
.header-nav h4 {
  color: #252733;
  font-weight: 600;
  font-size: 23px;
  margin-right: 40px;
}

// NAV TOGLER
.header-nav .toggler {
  display: none;
  margin-right: 20px;
  cursor: pointer;
}

// MENU BREADCRUMB
.menu-breadcrumb {
  cursor: pointer;
  margin-right: 16px;
  font-size: 30px;
  color: #252733;
}

@media (max-width: 991.98px) {
  .ant-layout-header {
    padding: 11px 16px 24px;
  }
  .header-nav h4 {
    font-size: 14px;
    margin-right: unset;
  }

  // NAV TOGLER
  .header-nav .toggler {
    display: block;
  }
  // MENU BREADCRUMB
  .menu-breadcrumb {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ant-layout-header {
    padding: 16px 16px 30px;
  }
  .header-nav h4 {
    font-size: 16px;
    margin-right: unset;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .ant-layout-header {
    padding: 24px 16px;
  }
  .header-nav h4 {
    font-size: 16px;
    margin-right: unset;
  }
}
