.rtl .blog-details .list .item .content svg {
  margin-left: 24px;
  margin-right: 0;
}
.rtl .blog-details .index-info ul {
  margin-right: 30px;
  margin-left: 0;
}

@media (max-width: 991.98px) {
  .rtl .blog-details .list .item .content svg {
    margin-left: 10px;
    margin-right: 0;
  }

  // INDEX INFO
  .rtl .blog-details .index-info:not(:last-child) {
    margin-bottom: 20px;
  }
  .rtl .blog-details .index-info ul {
    margin-right: 20px;
    margin-left: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .rtl .blog-details .list .item .content svg {
    margin-left: 14px;
    margin-right: 0;
  }
  .rtl .blog-details .index-info ul {
    margin-right: 24px;
    margin-left: 0;
  }
}