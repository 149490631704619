.form-type_4 label {
  font-size: 0.875rem;
  color: #6d6d6d;
  margin-bottom: 8px;
}
.form-type_4 label span {
  color: #aaa;
}

// RADIO CARD
.form-type_4 .radio-card {
  background-color: #FFF;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 1rem 1.25rem;
}

// UPLOAD CARD 2
.upload-card_2 {
  .inner {
    border-radius: 0.75rem;
    border: 1px solid rgba(143, 192, 69, 0.3);
    background: rgba(143, 192, 69, 0.05);
    padding: 1.875rem 1.5rem;
    text-align: center;

    .image {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 9.25rem;
      height: 9.25rem;
      margin: 0 auto 1.875rem;
      border-radius: 50%;
      position: relative;

      .icon-cover {
        width: 2.5rem;
        height: 2.5rem;
        background-color: white;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    h5 {
      color: #8fc045;
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    p {
      color: #6d6d6d;
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }
}

// UPLOAD GENERAL 2
.upload-general-card {
  background-color: #FFF;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 1rem;
}

// ANT INPUT
.form-type_4 .ant-input {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
  padding: 1rem 0.875rem;
  font-size: 1rem;
  color: #262f39;
  font-weight: 500;
}
.form-type_4 .ant-input::placeholder {
  color: #949494;
  font-size: 1rem;
}
.form-type_4 .ant-input:focus {
  border: 1px solid #8fc045;
}

// RADIO INPUT
.form-type_4 .ant-radio-wrapper span.ant-radio + * {
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}
.form-type_4 .ant-radio-wrapper {
  margin-bottom: 0 !important;
}

// CHECKBOX
.form-type_4 .ant-checkbox-wrapper span:nth-child(2) {
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}

// DATE PICKER
.form-type_4 .ant-picker {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  width: 100%;
}
.form-type_4 .ant-picker-focused {
  border: 1px solid #8fc045;
  box-shadow: unset;
}
.form-type_4 .ant-picker-input > input {
  font-size: 1rem;
  color: #262f39;
  font-weight: 500;
}
.form-type_4 .ant-picker-input > input::placeholder {
  font-size: 1rem;
}

// UPLOAD
.form-type_4 .upload-container {
  background: rgba(143, 192, 69, 0.1);
  color: #8fc045;
  font-size: 1rem;
  border: 1px dashed #8fc045;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.01rem;
}
.form-type_4 .upload-container svg {
  width: 1.25rem;
  margin-right: 0.75rem;
}
.form-type_4 .upload-container svg path {
  stroke: #8fc045;
}

// SELECT INPUT
.form-type_4 .ant-select {
  padding: 0;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
}
.form-type_4 .ant-select-selection-placeholder {
  color: #949494;
  font-size: 1rem;
}
.form-type_4 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #262f39;
  font-size: 1rem;
  padding: 0.8rem 0.875rem;
  height: 3.6rem;
  line-height: 3.6rem;
}
.form-type_4 .ant-select-item-option-content {
  white-space: normal;
}

@media (max-width: 575.98px) {
  .form-type_4
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0.36rem 1.1rem;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .form-type_4
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0.5rem 0.875rem;
  }
}
@media (min-width: 991.98px) and (max-width: 1199.98px) {
  .form-type_4
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0.5rem 0.875rem;
  }
}

// SELECT DROPDOWN
.ant-select-dropdown.form-type_4 {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 0;
}
.ant-select-dropdown.form-type_4 .ant-select-item-option-content {
  color: #262f39;
  font-size: 0.875rem;
  padding: 0.625rem 0.785rem;
  font-weight: 500;
}
.ant-select-dropdown.form-type_4 .ant-select-item {
  padding: 0;
}
.ant-select-dropdown.form-type_4 .ant-select-item:not(:last-child) {
  border-bottom: 1px solid rgba(143, 192, 69, 0.3);
}
.ant-select-dropdown.form-type_4
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #e3e3e3;
  color: #262f39;
}
.ant-select-dropdown.form-type_4 .ant-select-item-option-selected,
.ant-select-dropdown.form-type_4 .ant-select-item-option-active,
.ant-select-dropdown.form-type_4 .ant-select-item:hover {
  background: #e3e3e3;
  color: #262f39;
}
