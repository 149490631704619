.profile-pg {
  margin: 60px 30px 0;
}
.profile-pg h2 {
  color: #484848;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 30px;
}

// BUTTONS
.profile-pg .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}
.profile-pg .buttons .ant-btn {
  border-radius: 8px;
  width: 118px;
  padding: 14px 0;
  font-weight: 600;
  font-size: 16px;
  height: unset;
  border: unset;
  box-shadow: unset;
}
.profile-pg .buttons .ant-btn.cancel {
  color: #9fa1a6;
}
.profile-pg .buttons .ant-btn.cancel.active {
  color: #262f39;
}
.profile-pg .buttons .ant-btn.save {
  background: #8fc045;
  color: #ffffff;
  margin-left: 8px;
}
.profile-pg .buttons .ant-btn.save:hover {
  background: #77aa2a;
}
