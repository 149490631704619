.dashboard-pg {
  margin: 50px 30px 0;
}

// ITEM
.dashboard-pg .item {
  border: 0.723777px solid #afafaf;
  border-radius: 7.23777px;
  padding: 30px 10px 30px 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dashboard-pg .item svg {
  width: 55px;
  height: 55px;
  margin-right: 40px;
  transition: 0.3s all ease-in-out;
}
.dashboard-pg .item:hover svg {
  transform: scale(1.05, 1.05);
}
.dashboard-pg .item h5 {
  color: #484848;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 14px;
}

.dashboard-pg .item p {
  color: #a0a0a0;
  font-weight: 500;
  font-size: 15px;
}
.dashboard-pg .item p span {
  color: #484848;
  font-weight: 700;
  font-size: 30px;
  margin-right: 12px;
}

.request-status-card {
  background-color: #FFF;
  border: 1px solid #DDD;
  border-radius: 0.75rem; 
  padding: 1rem;
  cursor: pointer;

  &:hover {
    border: 1px solid transparent;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  }
}

@media (max-width: 991.98px) {
  .dashboard-pg {
    margin: 16px 12px 0;
  }

  // ITEM
  .dashboard-pg .item {
    padding: 20px 10px 20px 20px;
  }
  .dashboard-pg .item svg {
    margin-right: 25px;
  }
  .dashboard-pg .item h5 {
    color: #484848;
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 14px;
  }

  .dashboard-pg .item p {
    color: #a0a0a0;
    font-weight: 500;
    font-size: 15px;
  }
  .dashboard-pg .item p span {
    color: #484848;
    font-weight: 700;
    font-size: 30px;
    margin-right: 12px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .dashboard-pg .item {
    padding: 20px 10px 20px 20px;
  }
  .dashboard-pg .item svg {
    margin-right: 25px;
  }
  .dashboard-pg .item p span {
    font-size: 28px;
    margin-right: 12px;
  }
}