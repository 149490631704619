#main-form-style {
  .ant-picker {
    background: rgba(221, 221, 221, 0.2);
    border-radius: 0.5rem;
    padding: 1rem 0.875rem;
    border: 1px solid #ddd;
    width: 100%;
  }
  .ant-picker-input input::placeholder {
    font-size: 1rem;
    color: #aaa;
  }
  .ant-picker-input input {
    color: #262f39;
    font-size: 1rem;
    font-weight: 600 !important;
  }
  .ant-picker-focused {
    box-shadow: unset;
    outline: 0;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border: 1px solid #8fc045;
  }
}
