.profile-form .ant-select {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 13px 8px;
  display: block;
  width: 100%;
}
.profile-form .ant-select-selection-placeholder {
  color: #656565;
  font-weight: 500;
  font-size: 14px;
}
.profile-form .ant-select.ant-select-focused .ant-select-selector {
  box-shadow: unset !important;
}
.profile-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: unset;
  color: #262f39;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
}

// ADDON
.ant-input-number-group-addon {
  background: #f5f5f5;
  border: unset;
  border-radius: 5px;
  border-left: 1px solid rgba(38, 47, 57, 0.2) !important;
}
.ant-input-number-group-addon
  .ant-select.ant-select-single.ant-select-show-arrow {
  width: 90px;
}

@media (max-width: 1199.98px) {
  .profile-form .ant-select {
    padding: 7px 6px;
  }
  .profile-form .ant-select-selection-placeholder {
    font-size: 12px;
  }
  .profile-form
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    font-size: 12px;
  }
  .profile-form .ant-select svg {
    width: 10px;
  }
}
