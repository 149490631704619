// INPUT NO BORDER
.ant-input.input-no-border {
  background-color: transparent;
  border: unset;
  border-radius: 0;
  padding: 0;
  font-size: 0.875rem;
  color: #262f39;
}

// INPUT BORDERED BOTTOM
.ant-input.input-b-border {
  background-color: transparent;
  border: unset;
  border-bottom: 1px solid #dddddd;
  border-radius: 0;
  padding: 0 0 8px 0;
  font-size: 0.875rem;
  color: #262f39;
}
.ant-input.input-b-border::placeholder {
  color: #6d6d6d;
  font-weight: 400;
}

// ** SMALL INPUT
.ant-input.input-b-border.sm-input,
.ant-input.input-b-border.sm-input::placeholder {
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0 0 4px 0;
}

// ** MEDIUM INPUT
.ant-input.input-b-border.md-input {
  font-weight: 700;
  font-size: 1rem;
}
.ant-input.input-b-border.md-input::placeholder {
  font-size: 1rem;
}

// ** LARGE INPUT
.ant-input.input-b-border.lg-input,
.ant-input.input-b-border.lg-input::placeholder {
  font-weight: 700;
  font-size: 1.75rem;
}

// ** LIGHT GREEN
.ant-input.light-green {
  background: rgba(143, 192, 69, 0.2);
  border-bottom: 1px solid #8fc045;
  padding: 10px 12px;
  border-radius: 0.25rem 0.25rem 0 0;
}

// ** LIGHT BLUE
.ant-input.emerald {
  color: #1f8181;
  border-bottom: 1px solid #1f8181;
  font-weight: 500;
  padding: 10px 12px;
  border-radius: 0.25rem 0.25rem 0 0;
}

// --------------------------------------------------------------------

// NORMAL INPUT
.ant-input-affix-wrapper.normal-input,
.ant-input.normal-input {
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
  padding: 0.625rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
}
.ant-input.normal-input::placeholder {
  color: #6d6d6d;
  font-size: 0.75rem;
  font-weight: 400;
}

// --------------------------------------------------------------------

// AFFIX WRAPPER
.ant-input-affix-wrapper.normal-input .ant-input {
  background-color: #fff;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0;
}
.ant-input-affix-wrapper.normal-input .ant-input::placeholder {
  color: #6d6d6d;
  font-size: 0.75rem;
}

// --------------------------------------------------------------------

// SWITCH
.ant-switch-checked {
  background: #8fc045;
}

// --------------------------------------------------------------------

// ANT UPLOAD
.ant-upload {
  width: 100%;
}

// --------------------------------------------------------------------

// DOTS OVERLAY
.dots-overlay {
  box-shadow: 0px 2px 16px rgba(0, 48, 75, 0.1);
}
.dots-overlay li {
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.688rem 1rem;
  cursor: pointer;
}
.dots-overlay li:hover {
  background: rgba(143, 192, 69, 0.3);
}

// ------------------------ SUB PART COLOR -----------------------------

.ant-input.light-blue {
  background: rgba(0, 111, 173, 0.2);
  border-bottom: 1px solid #006fad;
  padding: 10px 12px;
  border-radius: 0.25rem 0.25rem 0 0;
}

// ------------------------- PREVIEW FORM ------------------------------
.form-display {
  width: 100%;
}
@media (max-width: 1199.98px) {
  .form-display {
    width: 100%;
  }
}

.preview-form .input:not(:last-child) {
  margin-bottom: 2.5rem;
}

.preview-form .input > label {
  color: #262f39;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: block;
}
.preview-form .input > label span {
  color: #d93f21;
}

.preview-form .description {
  color: #6d6d6d;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

// ANT INPUT
.preview-form .ant-input {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 0.688rem 0.75rem;
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}
.preview-form .ant-input::placeholder {
  font-size: 0.875rem;
}
.preview-form .ant-input:focus {
  border: 1px solid #8fc045;
}

// RADIO INPUT
.preview-form .ant-radio-wrapper span.ant-radio + * {
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}
.preview-form .ant-radio {
  transform: translateY(2px);
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #8fc045;
}
.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #8fc045;
}

// CHECKBOX
.preview-form .ant-checkbox-wrapper span:nth-child(2) {
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}

// DATE PICKER
.preview-form .ant-picker {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 0.588rem 0.75rem;
}
.preview-form .ant-picker-focused {
  border: 1px solid #8fc045;
  box-shadow: unset;
}
.preview-form .ant-picker-input > input {
  font-size: 0.875rem;
  color: #262f39;
  font-weight: 500;
}
.preview-form .ant-picker-input > input::placeholder {
  font-size: 0.875rem;
}

// UPLOAD
.preview-form .upload-container {
  background: rgba(143, 192, 69, 0.05);
  color: #8fc045;
  font-size: 0.875rem;
  border: 1px dashed #8fc045;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.813rem;
}
.preview-form .upload-container svg {
  width: 1rem;
  margin-right: 0.75rem;
}
.preview-form .upload-container svg path {
  stroke: #8fc045;
}

// SELECT INPUT
.preview-form .ant-select {
  padding: 0;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 4px;
}
.preview-form .ant-select-selection-placeholder {
  color: #aaaaaa;
  font-size: 0.875rem;
}
.preview-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #262f39;
  font-size: 0.875rem;
  padding: 0.28rem 0.875rem;
  height: 2.8rem;
}
.preview-form .ant-select-item-option-content {
  white-space: normal;
}

@media (max-width: 575.98px) {
  .preview-form
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0rem 0.875rem;
  }

  .new-form-actions .btn-green-outlined,
  .new-form-actions .btn-green {
    width: 12rem !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

// SELECT DROPDOWN
.ant-select-dropdown.preview-form {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 0;
}
.ant-select-dropdown.preview-form .ant-select-item-option-content {
  color: #262f39;
  font-size: 0.875rem;
  padding: 0.625rem 0.785rem;
  font-weight: 500;
}
.ant-select-dropdown.preview-form .ant-select-item {
  padding: 0;
}
.ant-select-dropdown.preview-form .ant-select-item:not(:last-child) {
  border-bottom: 1px solid rgba(143, 192, 69, 0.3);
}
.ant-select-dropdown.preview-form
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #e3e3e3;
  color: #262f39;
}
.ant-select-dropdown.preview-form .ant-select-item-option-selected,
.ant-select-dropdown.preview-form .ant-select-item-option-active,
.ant-select-dropdown.preview-form .ant-select-item:hover {
  background: #e3e3e3;
  color: #262f39;
}

// ----------------------------- ANSWERS ---------------------------------
.single-section:not(:last-child) {
  margin-bottom: 2rem;
}
.single-answer:not(:last-child) {
  margin-bottom: 1.5rem;
}
