.blog-details {
  padding: 40px 0 100px;
}
.blog-details .main-img {
  height: 280px;
  background-size: cover;
  background-position: center;
  margin-bottom: 30px;
  border-radius: 14px;
}

// MAIN CONTENT
// ------------
.blog-details .main h6 {
  color: #262f39;
  font-weight: 600;
  font-size: 19px;
  margin-bottom: 30px;
}
.blog-details .main h4 {
  color: #8fc045;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 24px;
}

// LIST
.blog-details .list {
  background: rgba(143, 192, 69, 0.1);
  border-radius: 8px;
  padding: 35px;
  margin-bottom: 32px;
}
.blog-details .list .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5c5c5c;
  cursor: pointer;
}
.blog-details .list .item:not(:last-child) {
  margin-bottom: 20px;
}
.blog-details .list .item .content svg {
  margin-right: 24px;
}

// INDEX INFO
.blog-details .index-info:not(:last-child) {
  margin-bottom: 30px;
}
.blog-details .index-info .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.blog-paragraph-title {
  margin-bottom: 24px;
}
.blog-paragraph-title h1, 
.blog-paragraph-title h2, 
.blog-paragraph-title h3, 
.blog-paragraph-title h4, 
.blog-paragraph-title h5, 
.blog-paragraph-title h6 {
  color: #8fc045;
  font-weight: 700;
  font-size: 20px;
}
.blog-details .blog-paragraph-title {
  margin-bottom: 0;
}
.blog-details .index-info h4 {
  margin-bottom: 0;
}
.blog-details .index-info p {
  color: #262f39;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 12px;
}
.blog-details .index-info ul {
  margin-left: 30px;
  margin-bottom: 12px;
}
.blog-details .index-info li {
  list-style-type: disc;
  line-height: 30px;
}
.blog-details .index-info a {
  color: #8fc045;
}
.blog-details .index-info img {
  max-width: 100%;
  border-radius: 5px;
  margin-top: 12px;
}

// SIDEBAR
.blog-sidebar {
  background: rgba(143, 192, 69, 0.1);
  border-radius: 8px;
  padding: 32px 16px 8px;
}

.blog-sidebar h4 {
  color: #262f39;
  font-weight: 600;
  font-size: 19px;
}
.blog-sidebar .item {
  padding: 24px 0;
  cursor: pointer;
}
.blog-sidebar .item:not(:last-child) {
  border-bottom: 1px solid #cfcfcf;
}
.blog-sidebar .item .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-sidebar .item .header svg {
  transition: 0.2s linear;
}
.blog-sidebar .item .header.active svg {
  transform: rotate(-180deg);
}
.blog-sidebar .header li {
  font-size: 19px;
  list-style-type: disc;
  color: #8fc045;
}
.blog-sidebar .header li span {
  font-size: 16px;
  color: #262f39;
  font-weight: 500;
}

.blog-sidebar .item .body {
  padding: 10px 0 0 24px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
}
.blog-sidebar .item .body.active {
  max-height: 2000px;
  transition: max-height 0.25s ease-out;
}
.blog-sidebar .body li {
  font-size: 14px;
  color: #262f39;
  font-weight: 500;
  line-height: 29px;
  cursor: pointer;
  transition: 0.2s;
}
.blog-sidebar .body li:hover {
  color: #8fc045;
}
.blog-sidebar .body li:not(:last-child) {
  margin-bottom: 8px;
}

.blog-sidebar .new-blogs {
  font-size: 14px;
  color: #262f39;
  font-weight: 500;
  margin: 16px 0;
}
.blog-sidebar .new-blogs li {
  list-style-type: disc;
  line-height: 29px;
  cursor: pointer;
  transition: 0.2s;
}
.blog-sidebar .new-blogs li:hover {
  color: #8fc045;
}

@media (max-width: 991.98px) {
  .blog-details {
    padding: 30px 0;
  }
  .blog-details .main-img {
    height: 130px;
    margin-bottom: 12px;
    border-radius: 5px;
  }

  // MAIN CONTENT
  // ------------
  .blog-details .main h6 {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .blog-details .main h4 {
    font-size: 14px;
    margin-bottom: 16px;
  }

  // LIST
  .blog-details .list {
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 20px;
  }
  .blog-details .list .item {
    font-size: 12px;
  }
  .blog-details .list .item:not(:last-child) {
    margin-bottom: 10px;
  }
  .blog-details .list .item .content svg {
    margin-right: 10px;
    width: 15px;
  }

  // INDEX INFO
  .blog-details .index-info:not(:last-child) {
    margin-bottom: 20px;
  }
  .blog-details .index-info .title {
    font-size: 14px;
    margin-bottom: 12px;
  }
  .blog-details .index-info h4 {
    margin-bottom: 0;
  }
  .blog-details .index-info p {
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 6px;
  }
  .blog-details .index-info ul {
    margin-left: 20px;
    margin-bottom: 12px;
  }
  .blog-details .index-info li {
    font-size: 12px;
    line-height: 22px;
  }
  .blog-details .index-info img {
    max-width: 100%;
    border-radius: 5px;
    margin-top: 6px;
  }

  // SIDEBAR
  .blog-sidebar {
    border-radius: 5px;
    padding: 24px 16px 8px;
  }

  .blog-sidebar h4 {
    font-size: 16px;
  }
  .blog-sidebar .item {
    padding: 12px 0;
  }
  .blog-sidebar .header li {
    font-size: 14px;
  }
  .blog-sidebar .header li span {
    font-size: 12px;
  }

  .blog-sidebar .item .body {
    padding: 10px 0 0 12px;
  }
  .blog-sidebar .body li {
    font-size: 12px;
    line-height: 22px;
  }

  .blog-sidebar .new-blogs {
    font-size: 12px;
  }
  .blog-sidebar .new-blogs li {
    line-height: 22px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .blog-details {
    padding: 30px 0;
  }
  .blog-details .main-img {
    height: 150px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .blog-details {
    padding: 40px 0 80px 0;
  }
  .blog-details .main-img {
    height: 200px;
    margin-bottom: 24px;
    border-radius: 10px;
  }

  // MAIN CONTENT
  // ------------
  .blog-details .main h6 {
    font-size: 14px;
    margin-bottom: 14px;
  }
  .blog-details .main h4 {
    font-size: 18px;
    margin-bottom: 24px;
  }

  // LIST
  .blog-details .list {
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 20px;
  }
  .blog-details .list .item {
    font-size: 14px;
  }
  .blog-details .list .item:not(:last-child) {
    margin-bottom: 10px;
  }
  .blog-details .list .item .content svg {
    margin-right: 14px;
    width: 18px;
  }

  // INDEX INFO
  .blog-details .index-info:not(:last-child) {
    margin-bottom: 24px;
  }
  .blog-details .index-info .title {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .blog-details .index-info h4 {
    margin-bottom: 0;
  }
  .blog-details .index-info p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 8px;
  }
  .blog-details .index-info ul {
    margin-left: 24px;
    margin-bottom: 12px;
  }
  .blog-details .index-info li {
    font-size: 14px;
    line-height: 26px;
  }
  .blog-details .index-info img {
    max-width: 100%;
    border-radius: 5px;
    margin-top: 6px;
  }

  // SIDEBAR
  .blog-sidebar {
    border-radius: 5px;
    padding: 24px 16px 8px;
  }

  .blog-sidebar h4 {
    font-size: 16px;
  }
  .blog-sidebar .item {
    padding: 12px 0;
  }
  .blog-sidebar .header li {
    font-size: 14px;
  }
  .blog-sidebar .header li span {
    font-size: 12px;
  }

  .blog-sidebar .item .body {
    padding: 10px 0 0 12px;
  }
  .blog-sidebar .body li {
    font-size: 12px;
    line-height: 22px;
  }

  .blog-sidebar .new-blogs {
    font-size: 12px;
  }
  .blog-sidebar .new-blogs li {
    line-height: 22px;
  }
}
