#main-form-style {
  .date-picker {
    label {
      font-size: 0.875rem;
      color: #6d6d6d;
      margin-bottom: 0.5rem;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__input-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      border: 1px solid #ddd;
      border-radius: 0.5rem;
      padding: 1rem 0.875rem;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;

      &:hover {
        border: 1px solid #8fc045;
      }
    }

    .react-datepicker__input-container input {
      color: #262F39;
      font-size: 1rem;
      font-weight: 600;
      padding: 0;
      width: 100%;
      border: unset;

      &::placeholder {
        font-size: 1rem;
        color: #AAA;
      }

      &:focus-visible {
        outline: unset;
      }
    }
  }
}
