.ant-btn.add-new {
  background: #8fc045;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;
  padding: 16px 0;
  border: unset;
  height: unset;
  width: 219px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-filters .ant-btn.add-new {
  height: 60px;
}
.ant-btn.add-new svg {
  margin-right: 10px;
}
.ant-btn.add-new:hover {
  background: #77aa2a;
}

// MAIN CARD
.main-card {
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  margin: 24px 30px 150px;
  padding: 27px 0 40px;
}

.main-card .ant-segmented {
  width: 50%;
  margin: auto;
  margin-bottom: 40px;
}

.ant-empty-description {
  color: #9fa2b4;
}

@media (max-width: 575.98px) {
  // MAIN CARD
  .main-card {
    border-radius: 4px;
    margin: 0 16px 150px;
    padding: 16px 0 16px;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  // MAIN CARD
  .main-card {
    border-radius: 6px;
    margin: 0 16px 150px;
    padding: 24px 0 24px;
  }

  .ant-btn.add-new {
    font-size: 12px;
    border-radius: 6px;
    padding: 16px 0;
    width: 219px;
  }
  .table-filters .ant-btn.add-new {
    height: 45px;
  }
  .ant-btn.add-new svg {
    width: 12px;
    margin-right: 12px;
  }
}
