@import './upload';
@import './input';
@import './file-upload';
@import './icon_upload';
@import './select';
@import './date-picker';

.profile-form {
  border: 1px solid #c8c8c8;
  border-radius: 13px;
  padding: 39px 30px 35px;
}
.profile-form .label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-form label {
  color: #262f39;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 12px;
}
.profile-form .label .ant-btn {
  color: #8fc045;
  font-weight: 600;
  font-size: 14px;
  border: unset;
  padding: unset;
  box-shadow: unset;
  transform: translateY(-5px);
}
.profile-form .label .ant-btn span {
  text-decoration: underline;
}
label img {
  width: 25px;
  height: 25px;
  margin: 0 8px;
}
