.ant-modal.pdf-modal {
  padding: 0;
  margin: 0;
}
.ant-modal.pdf-modal .ant-modal-body {
  padding: 0;
  margin: 0;
}

// CLOSE ICON
.ant-modal.pdf-modal .ant-modal-body .close {
  position: absolute;
  top: unset;
  bottom: 29px;
  right: 35px;
  cursor: pointer;
}
