.file-upload .ant-upload.ant-upload-select {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #CFD3DA;
  border-radius: 6px;
  padding: 10px 8px;
}
.file-upload span.ant-upload {
  color: #626D7D;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file-upload span.ant-upload svg path {
  stroke: #8FC045;
}

.ant-upload-list.ant-upload-list-text {
  display: none;
}